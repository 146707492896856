import {Popover} from "antd"
import moment from "moment"
import {SetStateAction, SyntheticEvent, useContext, useEffect, useState} from "react"
import {useNavigate, useOutletContext} from "react-router"
import {Link} from "react-router-dom"
import profileImage from "src/assets/images/profile-icon.svg"
import {ORDER_STATUS_NAME} from "src/components/shared/constants"
import {ImageField} from "src/components/shared/image-loader/image"
import {OrderRequestListModel} from "src/models/manager/order/order-request-list.model"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"
import {uniqByReduce} from "src/utils/uniqueArray"
import {OrderStatus, PaymentStatus} from "../constants"
import {OrderCardDocument} from "./order-document"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import clsx from "clsx"
import {ConfigContext} from "src/app"
import {OrderListContext} from "./orders.context"

export function OrderCard(config: {order: OrderRequestListModel}) {
  const {t} = useTranslation()
  const order = config.order
  const navigate = useNavigate()
  const {selection = false, selected = [], setSelected} = useContext(OrderListContext) || {}
  const {settings, isMobile} = useContext(ConfigContext)
  const [collapsed, setCollapsed] = useState(true)
  const [color, setColor] = useState<string>("")
  const [showHistory, setShowHistory] = useState(false)

  const inventories = order.inventories.map((inventory) => (
    <div
      key={inventory.id}
      style={{lineHeight: "20px"}}
      className={clsx(
        "mb-1 text-[13px] text-gray-500",
        ((inventory.started && order.receivements.length > 0 && !inventory.returned) || order.time_exceed) &&
          "text-alert"
      )}>
      - {inventory.inventory_name} ({inventory.inventory_unique_id})
    </div>
  ))

  const user = (title: string, name: string) => (
    <div className="flex justify-between mb-2">
      <div className="text-sm font-medium">{title}</div>
      <span className="text-gray-500 text-[13px] text-end">{name}</span>
    </div>
  )

  const comment = order.comments.length > 0 && order.comments[order.comments.length - 1]

  const navigateOrder = () => {
    if (selection) return
    const selectedText = window.getSelection().toString()
    if (!selectedText) navigate(`/orders/${order.id}/all`)
  }

  const onIdCopy = (e) => {
    if (selection) return
    e.stopPropagation()
    navigator.clipboard.writeText(String(order.id))
    toast(t("common.toast.number_copied"))
  }

  const onPhoneCopy = (e) => {
    if (selection) return
    e.stopPropagation()
    navigator.clipboard.writeText(String(order.client.phone))
    toast("t('common.toast.tel_number_copied')")
  }

  const isYumePlus = Number.isInteger(order.plus_id)
  const isNew = moment().diff(moment(order.created_at)) < 60 * 60 * 1000 && order.status === OrderStatus.REQUEST
  const clientIsLate = moment() > moment(order.rent_start) && order.status === OrderStatus.RESERVED

  useEffect(() => {
    if (order && order.status_color) setColor(settings[order.status_color])
  }, [settings, order])

  const onClick = (e: SyntheticEvent) => {
    if (!selection) return
    e.stopPropagation()
    if (selected.includes(order.id)) {
      setSelected((p) => p.filter((s) => s !== order.id))
    } else {
      setSelected((p) => [...p, order.id])
    }
  }

  if (isMobile) {
    return (
      <div
        className={`order-card flex flex-col cursor-pointer bg-white shadow-lg ${selected.includes(order.id) ? "outline-4 outline-dashed outline-primary" : ""}`}
        onClick={onClick}>
        <div onClick={navigateOrder}>
          <div
            className="flex justify-between items-start text-sm gap-2 font-semibold p-4 border-b border-gray-100"
            style={{backgroundColor: color}}>
            <div className="flex gap-1 scrollbar-hide" style={{overflowX: "scroll", borderRadius: 8}}>
              <div className="btn-group">
                <div
                  className={clsx("order-card-status font-medium px-2 py-1 bg-white", {
                    "rounded-r-none": isNew || isYumePlus
                  })}
                  style={{color: color}}>
                  {t(ORDER_STATUS_NAME[order.status_color])}
                </div>
                {isNew && <div className="btn btn-red btn-color-white text-xs font-bold px-2 py-1">NEW</div>}
                {isYumePlus && (
                  <div className="btn btn-color-white font-bold px-2 py-1 bg-[#FA541C]" style={{color: "white"}}>
                    yume+
                  </div>
                )}
              </div>
              {order.deleted && (
                <div className="btn btn-red btn-color-white font-medium px-2 py-1">{t("common.archive.archived")}</div>
              )}
            </div>
            {order.id && (
              <button
                className="btn px-0 py-1 text-[13px] font-medium items-center text-white gap-1"
                onClick={onIdCopy}>
                <Icon icon="content_copy" /> №{order.id}
              </button>
            )}
          </div>
          <div className="flex flex-col items-start gap-4 p-4 border-b border-gray-100">
            <div className="flex justify-between w-full">
              <div className="flex flex-col flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-gray-350 font-normal text-[13px]">Дата выдачи</div>
                  <div className="flex gap-1 items-center font-medium text-[15px] text-black">
                    {toDateTime(order.rent_start, "DD MMMM, HH:mm")}
                    {clientIsLate && <div className="w-2 h-2 rounded-full bg-alert" />}
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-gray-350 font-normal text-[13px]">Сумма заказа</div>
                  <div className="font-medium text-[15px] text-black">{toPrice(order.price)}</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex flex-col flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-gray-350 font-normal text-[13px]">Дата возврата</div>
                  <div className="font-medium text-[15px] text-black">
                    {toDateTime(order.rent_end, "DD MMMM, HH:mm")}
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-gray-350 font-normal text-[13px]">Клиент</div>
                  {order.client ? (
                    <div className="flex gap-2 items-center font-medium text-[15px] text-black">
                      <ImageField
                        src={order.client.avatar}
                        fallbackimage={profileImage}
                        draggable={false}
                        className="content-cover w-6 h-6 rounded-full"
                      />
                      <span>{order.client?.name}</span>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center font-medium text-[15px] text-black">
                      <Icon className="text-xl" icon="groups" />
                      <div className="font-semibold text-sm">{t("orders.list.no_client")}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={"p-4 min-h-14 border-b border-gray-100"}>
            {[PaymentStatus.PENDING, PaymentStatus.PARTLY_PAID].includes(order.payment_status) && (
              <div className="btn btn-red-8 btn-color-red w-full font-medium min-h-14">
                {t("common.payment_status.not_paid")} ({toPrice(+order.price_discount - +order.paid_amount)})
              </div>
            )}

            {order.payment_status === PaymentStatus.PAID && (
              <div className="btn btn-green-8 btn-color-green w-full gap-2 font-medium min-h-14">
                {t("common.payment_status.paid")}
              </div>
            )}
          </div>
          <div className="flex flex-col p-4 border-b border-gray-100">
            <div
              className={"flex justify-between"}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowHistory((prev) => !prev)
              }}>
              <span className="font-normal text-[13px] text-gray-300 mb-1">История аренды</span>
              <Icon icon="keyboard_arrow_down" className={showHistory && "rotate-180"} />
            </div>
            {showHistory && (
              <div className="order-card-content flex-1 px-0 pb-0" onClick={navigateOrder}>
                {(order.cancelled_by && order.reserved_by.trim()) ||
                order.issues.length > 0 ||
                order.receivements.length > 0 ||
                (order.cancelled_by && order.cancelled_by.trim()) ? (
                  <>
                    {order.reserved_by && user(t("orders.card.reserved_by"), order.reserved_by)}
                    {order.issues.length > 0 &&
                      user(
                        t("orders.card.issued_by"),
                        uniqByReduce<string>(order.issues.map((item) => item.user)).join(", ")
                      )}
                    {order.receivements.length > 0 &&
                      user(
                        t("orders.card.received_by"),
                        uniqByReduce<string>(order.receivements.map((item) => item.user)).join(", ")
                      )}
                    {order.cancelled_by.trim() && user(t("orders.card.cancelled_by"), order.cancelled_by)}
                  </>
                ) : (
                  <div className={"flex justify-between items-center"}>История пустая</div>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col p-4 border-b border-gray-100">
            <span className="font-normal text-[13px] text-gray-300 mb-1">В аренде</span>
            <div className="flex items-center justify-start p-3 gap-3 rounded-lg bg-gradient-to-r from-[#F4F5FA] to-[#F4F5FA80] w-full min-h-14">
              <div className="flex items-center w-full relative gap-3">
                <div className="relative h-8 min-w-12">
                  {order.inventories.length > 0 && (
                    <ImageField
                      src={order.inventories[0]?.image}
                      draggable={false}
                      className="absolute top-0 border-2 border-[#F4F5FA] min-w-8 min-h-8 rounded-full bg-gray-200 p-1 left-0"
                    />
                  )}
                  {order.inventories.length > 1 ? (
                    <div className="flex justify-center items-center absolute text-xs font-medium left-4 border-2 border-[#F4F5FA] bg-gray-200 min-h-8 min-w-8 rounded-full">
                      +{order.inventories.length - 1}
                    </div>
                  ) : (
                    <ImageField
                      src={order.inventories[1]?.image}
                      draggable={false}
                      className="absolute top-0 border-2 border-[#F4F5FA] min-w-8 min-h-8 max-w-8 max-h-8 rounded-full bg-gray-200 p-1 left-4"
                    />
                  )}
                </div>

                <div className="font-medium text-sm text-black whitespace-nowrap text-ellipsis overflow-hidden">
                  {order.inventories.map((inventory) => inventory.inventory_name).join(", ")}
                </div>
              </div>
            </div>
          </div>

          <OrderCardDocument order={config.order} view="grid" />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`order-card flex flex-col cursor-pointer ${selected.includes(order.id) ? "outline-4 outline-dashed outline-primary" : ""}`}
      style={{backgroundColor: color}}
      onClick={onClick}>
      <div className="p-3" onClick={navigateOrder}>
        <div className="flex justify-between items-start text-sm gap-2 font-semibold">
          <div className="flex gap-1 scrollbar-hide" style={{overflowX: "scroll", borderRadius: 8}}>
            <div className="btn-group">
              <div
                className={clsx("order-card-status font-medium px-2 py-1 bg-white", {
                  "rounded-r-none": isNew || isYumePlus
                })}
                style={{color: color}}>
                {t(ORDER_STATUS_NAME[order.status_color])}
              </div>
              {isNew && <div className="btn btn-red btn-color-white text-xs font-bold px-2 py-1">NEW</div>}
              {isYumePlus && (
                <div className="btn btn-color-white font-bold px-2 py-1 bg-[#FA541C]" style={{color: "white"}}>
                  yume+
                </div>
              )}
            </div>
            {order.deleted && (
              <div className="btn btn-red btn-color-white font-medium px-2 py-1">{t("common.archive.archived")}</div>
            )}
          </div>
          {order.id && (
            <button className="btn px-0 py-1 text-[13px] font-medium items-center text-white gap-1" onClick={onIdCopy}>
              <Icon icon="content_copy" /> №{order.id}
            </button>
          )}
        </div>
        <div className="flex items-start gap-2 mt-2">
          <Popover
            className={clsx(
              "order-card-header-wrapper flex flex-row items-center gap-2 justify-between",
              clientIsLate && "red-outlined"
            )}
            content={
              !!order.rent_fact_start && (
                <span className="text-nowrap">
                  {t("orders.main.rent_fact_start")} - {toDateTime(order.rent_fact_start)}
                </span>
              )
            }>
            <div className="flex flex-col gap-2">
              <div className="color-white">{t("orders.list.rent_start")}</div>
              <div className="color-white font-semibold text-sm">{toDateTime(order.rent_start, "DD MMMM, HH:mm")}</div>
            </div>
            {clientIsLate && <div className="w-2 h-2 rounded-full bg-alert" />}
          </Popover>
          <Popover
            className="order-card-header-wrapper"
            content={
              !!order.rent_fact_end && (
                <span className="text-nowrap">
                  {t("orders.main.rent_fact_end")} - {toDateTime(order.rent_fact_end)}
                </span>
              )
            }>
            <div className="color-white">{t("orders.list.rent_end")}</div>
            <div className="color-white font-semibold text-sm">{toDateTime(order.rent_end, "DD MMMM, HH:mm")}</div>
          </Popover>
        </div>
        {settings && settings.order_client && (
          <div className="flex items-start mt-2">
            {order.client ? (
              <Popover
                trigger="hover"
                overlayInnerStyle={{padding: 6}}
                className="order-card-header-wrapper flex-row items-center"
                content={() => (
                  <div className="flex gap-1 flex-col">
                    <Link
                      to={`/clients/${order.client.id}`}
                      onClick={(e) => e.stopPropagation()}
                      className="btn btn-gray-50 text-start py-1">
                      {t("orders.list.go_client")}
                    </Link>
                    <button className="btn btn-gray-50 text-start py-1" onClick={onPhoneCopy}>
                      {t("orders.list.copy_phone_number")}
                    </button>
                  </div>
                )}>
                <Icon className="text-2xl color-white" icon="groups" />
                <div className="flex flex-col gap-1">
                  <div className="color-white font-semibold text-sm">{order.client.name}</div>
                  <div className="color-white font-normal text-sm">{order.client.phone}</div>
                </div>
              </Popover>
            ) : (
              <div className="order-card-header-wrapper flex-row items-center gap-2">
                <Icon className="text-2xl color-white" icon="groups" />
                <div className="color-white font-semibold text-sm">{t("orders.list.no_client")}</div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="order-card-content-wrapper">
        <div className="order-card-content flex flex-col gap-3" onClick={navigateOrder}>
          <div className="text-sm font-semibold">{t("orders.card.overall_title")}</div>
          <div className="flex justify-between text-[13px]">
            <div className="text-gray-500">{t("orders.card.price_title")}</div>
            <div className="text-black font-bold text-nowrap">{toPrice(order.price_discount)}</div>
          </div>
          <div className="text-[13px]">
            <div className="flex justify-between">
              <div className="text-gray-500">{t("orders.card.sum_title")}</div>
              <div className="text-black font-medium text-nowrap">{toPrice(order.price)}</div>
            </div>
            {Number(order.discount_amount) !== 0 && (
              <div className="flex justify-between text-[13px] mt-2">
                <div className="text-gray-500">{t("orders.card.discount_title")}</div>
                <div className="text-black font-medium text-nowrap">{toPrice(-order.discount_amount)}</div>
              </div>
            )}
          </div>

          {[PaymentStatus.PENDING, PaymentStatus.PARTLY_PAID].includes(order.payment_status) && (
            <div className="btn btn-red-8 btn-color-red w-full font-medium">
              {t("common.payment_status.not_paid")} ({toPrice(+order.price_discount - +order.paid_amount)})
            </div>
          )}

          {order.payment_status === PaymentStatus.PAID && (
            <div className="btn btn-green-8 btn-color-green w-full gap-2 font-medium">
              {t("common.payment_status.paid")}
            </div>
          )}

          {comment && (
            <div className="flex gap-2 flex-col order-card-content-comment shadow">
              <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center text-sm font-bold">
                  <ImageField
                    src={comment.owner.avatar}
                    fallbackimage={profileImage}
                    className="object-cover w-8 h-8 rounded-full"
                  />
                  {comment.owner.first_name} {comment.owner.last_name}
                </div>
                {order.comments.length > 1 && (
                  <div
                    style={{
                      padding: 4,
                      borderRadius: "50%",
                      width: 16,
                      height: 16
                    }}
                    className="flex items-center justify-center text-xs font-medium bg-alert color-white">
                    {order.comments.length}
                  </div>
                )}
              </div>
              <div className="text-[13px] text-gray-400 overflow overflow line-clamp-2">{comment.body}</div>
            </div>
          )}
        </div>

        <div className="order-card-content" onClick={navigateOrder}>
          <div className="text-sm font-bold mb-3">
            {t("orders.card.inventory_title")} ({toPrice(order.price_inventory)})
          </div>
          {collapsed ? inventories.slice(0, 5) : inventories}
          {inventories.length > 5 && collapsed && (
            <div
              className="flex items-center gap-2 mt-3 text-gray-400 text-[13px]"
              onClick={(e) => {
                if (selection) return
                e.stopPropagation()
                setCollapsed(false)
              }}>
              <Icon icon="expand_more" />
              <span>{t("orders.list.show_more", {count: inventories.length - 5})}</span>
            </div>
          )}
          {!collapsed && (
            <div
              className="flex items-center gap-2 mt-3 text-gray-400 text-[13px]"
              onClick={(e) => {
                if (selection) return
                e.stopPropagation()
                setCollapsed(true)
              }}>
              <Icon icon="expand_less" />
              <span>{t("orders.list.collapse")}</span>
            </div>
          )}
        </div>

        {order.services.length > 0 && (
          <div className="order-card-content flex flex-col" style={{gap: 12}} onClick={navigateOrder}>
            <div className="text-sm font-bold">
              {t("orders.card.service_title")} ({toPrice(order.price_service)})
            </div>
            <div className="flex flex-col gap-1">
              {order.services.map((service) => (
                <div key={service.id} className="mb-1 text-[13px] text-gray-500">
                  - {service.service} ({service.worker})
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="order-card-content flex-1" onClick={navigateOrder}>
          {order.reserved_by && order.reserved_by.trim() && user(t("orders.card.reserved_by"), order.reserved_by)}
          {order.issues.length > 0 &&
            user(t("orders.card.issued_by"), uniqByReduce<string>(order.issues.map((item) => item.user)).join(", "))}
          {order.receivements.length > 0 &&
            user(
              t("orders.card.received_by"),
              uniqByReduce<string>(order.receivements.map((item) => item.user)).join(", ")
            )}
          {order.cancelled_by && user(t("orders.card.cancelled_by"), order.cancelled_by)}
        </div>

        <OrderCardDocument order={config.order} />
      </div>
    </div>
  )
}
