import {Link, useNavigate} from "react-router-dom"
import toDateTime from "src/utils/date-time"
import {useTranslation} from "react-i18next"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import React, {useCallback, useContext} from "react"
import {ConfigContext} from "src/app"
import {GroupType} from "src/services/auth.service"
import {getOrderStatus} from "../../../details/help/getOrderStatus"
import Icon from "../../../../../components/shared/components/material-icon"
import {Tooltip} from "antd"

const RideCard = ({ride}: {ride: TransferRideModel}) => {
  const {
    id,
    transfer,
    end_address,
    start_address,
    driver_user_id,
    driver_user_name,
    driver_inventory_name,
    start_extra,
    client_name,
    client_phone,
    inventory_group,
    status,
    start_at
  } = ride
  const {text, bg} = getOrderStatus(status)
  const {profile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const navigate = useNavigate()

  const onNavigate = useCallback(() => {
    if (!profile) return
    if (profile.group_type === GroupType.DRIVER) navigate(`/rides/${id}`)
    else {
      navigate(`/transportation/${transfer}/ride/${id}`)
    }
  }, [profile, id, transfer])

  return (
    <div className="w-full bg-white rounded-lg cursor-pointer flex flex-col justify-between max-w-[720px]">
      <div onClick={onNavigate}>
        <div className="p-3 flex flex-col gap-3 border-b border-gray-100">
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap">
              <span className="block font-medium text-[16px] text-gray-900">{toDateTime(start_at)}</span>
              <div className="text-[13px] text-gray-370">№{id}</div>
            </div>
            <div className={`text-[13px] px-4 py-2  text-white rounded-lg`} style={{backgroundColor: bg}}>
              {t(text as any)}
            </div>
          </div>
        </div>
        <div className="p-3 flex flex-col gap-3 border-b border-gray-100 last:border-none">
          <div className="flex gap-2">
            <div className="flex items-center gap-1">
              {start_extra?.tour ? (
                <Tooltip title={t("transfers.main.excursion")}>
                  <div className="rotate-90">
                    <Icon icon={"sync_alt"} />
                  </div>
                </Tooltip>
              ) : (
                <div className="flex flex-col items-end gap-2">
                  <span className="font-bold">{t("transfers.form.from")}:</span>
                  <span className="font-bold">{t("transfers.form.to")}:</span>
                </div>
              )}
              <div className="flex flex-col gap-2">
                <span>{start_address.name}</span>
                <span>{end_address.name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {profile.group_type === GroupType.MANAGER && client_name && (
        <div className="flex justify-between gap-2 border-b border-gray-100 last:border-non">
          <div className="flex w-[49%] border-r border-gray-100 p-3 gap-2">
            <Icon icon={"directions_car"} />
            <span className="block font-medium text-[15px] text-gray-900">{inventory_group?.name}</span>
          </div>
          <div className="flex w-[50%] p-3 gap-2">
            <Icon icon={"person"} />
            <span className="block font-medium text-[15px] text-gray-900">{client_name}</span>
          </div>
        </div>
      )}

      {profile.group_type !== GroupType.DRIVER && driver_user_id && (
        <div className="flex justify-between gap-2">
          <div className="flex w-[49%] border-r border-gray-100 p-3 gap-2">
            <Icon icon={"directions_car"} />
            <span className="block font-medium text-[15px] text-gray-900">{driver_inventory_name}</span>
          </div>
          <div className="flex w-[50%] p-3 gap-2">
            <Icon icon={"search_hands_free"} />
            <span className={`block font-medium text-[15px] ${driver_user_name ? "text-gray-900" : "text-gray-370"}`}>
              {driver_user_name ? driver_user_name : "Not Assigned"}
            </span>
          </div>
        </div>
      )}
      {profile.group_type === GroupType.DRIVER && client_name && (
        <div className="flex justify-between gap-2">
          <div className="flex w-[49%] border-r border-gray-100 p-3 gap-2">
            <Icon icon={"phone"} />
            <Link to={`tel:${ride?.client_phone}`} className="flex items-center font-medium text-[13px] text-gray-900">
              {ride?.client_phone}
            </Link>
          </div>
          <div className="flex w-[50%] p-3 gap-2">
            <Icon icon={"person"} />
            <span className="block font-medium text-[15px] text-gray-900">{client_name}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default RideCard
