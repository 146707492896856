import {createContext, useContext, useEffect, useState} from "react"
import {FormProvider, useFieldArray, useForm} from "react-hook-form"
import {useParams} from "react-router"
import {DetailOrderRequestModel} from "src/models/manager/order/order-request-detail.model"
import {OrderInventoryUnavailable} from "src/models/manager/order/order-request-inventory-unavailable.model"
import inventoryService from "src/services/inventory/inventory.service"
import orderInventoryService from "src/services/order-requests/order-inventory.service"
import orderRequestServiceService from "src/services/order-requests/order-service.service"
import orderService from "src/services/order-requests/order.service"
import {getMinMaxDate} from "src/utils/min-max-date"
import {OrderContextType, OrderForm, OrderRequestInventoryError} from "./order-context.model"
import {firstValueFrom} from "rxjs"
import {OrderRequestInventoryModel} from "src/models/manager/order/order-request-inventory"
import {isNil} from "src/utils/isNil"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import modalService from "src/components/modal/global/modal.service"
import detectServiceChange from "../change-detection/service"
import checkTimeRange from "../unavailability-check"
import {detectChanges} from "../change-detection/inventory"
import OrderDetailInventoryListComponent from "../inventories/inventory-list/inventory-add"
import {OrderRequestDeliveryModel} from "src/models/manager/order/order-request-delivery.model"
import {InventoryError} from "./error.modal"
import {InventoryGroupModel} from "../../../../models/manager/inventory/inventory-group.model"
import keygen from "../../../../utils/keygen"
import inventoryGroupService from "../../../../services/inventory/inventory-group.service"
import {ConfigContext} from "src/app"
import {InventorySetModel} from "../../../../models/manager/inventory/inventory-set.model"
import {OrderDetailSetFilterComponent} from "../inventories/inventory-set-list/filter-modal/set-filter"
import {newSubsription, unsubsribe} from "src/services/cent"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import orderDocumentService from "src/services/order-requests/order-document.service"
import moment from "moment"
import {OrderRequestPaymentModel} from "src/models/manager/order/order-request-payment"
import orderPaymentService from "src/services/order-requests/order-payment.service"
import {CustomFieldChoiceList} from "src/pages/settings/custom-field/field-choice"
import {useTranslation} from "react-i18next"
import {CustomModal} from "src/components/modal/global/customModal"

export const OrderContext = createContext<OrderContextType>(undefined)

export const OrderDetailProvider = ({children}: {children: JSX.Element}) => {
  const {id} = useParams<{id: string}>()
  const {t} = useTranslation()
  const {settings, tenant} = useContext(ConfigContext)
  const [order, setOrder] = useState<DetailOrderRequestModel>()
  const [deliveries, setDeliveries] = useState<OrderRequestDeliveryModel[]>([])
  const [inventories, setInventories] = useState<OrderRequestInventoryModel[]>([])
  const [payments, setPayments] = useState<OrderRequestPaymentModel[]>([])
  const [checkouts, setCheckouts] = useState<number[]>([])
  const [backCheckouts, setBackCheckouts] = useState<number[]>([])
  const [prematureStart, setPrematureStart] = useState<OrderInventoryUnavailable[]>([])
  const [flexOrder, setFlexOrder] = useState<boolean>(false)
  const [extraModal, setExtraModal] = useState<{show: boolean; keys: string[]}>({show: false, keys: []})

  const orderForm = useForm<OrderForm>()
  const formValue = orderForm.watch()

  const servicesForm = useFieldArray({
    control: orderForm.control,
    name: "services"
  })

  const createServicesForm = useFieldArray({
    control: orderForm.control,
    name: "create_services"
  })

  const addCheckout = (id: number) => {
    if (backCheckouts.length > 0) return
    if (checkouts.includes(id)) {
      setCheckouts(checkouts.filter((i) => i !== id))
    } else {
      setCheckouts([...checkouts, id])
    }
  }

  const addCheckouts = (ids: number[]) => {
    if (backCheckouts.length > 0) return
    if (checkouts.some((id) => ids.includes(id))) {
      setCheckouts(checkouts.filter((i) => !ids.includes(i)))
    } else {
      setCheckouts([...checkouts, ...ids])
    }
  }

  const addBackCheckout = (id: number) => {
    if (checkouts.length > 0) return
    if (backCheckouts.includes(id)) {
      setBackCheckouts(backCheckouts.filter((i) => i !== id))
    } else {
      setBackCheckouts([...backCheckouts, id])
    }
  }

  const addBackCheckouts = (ids: number[]) => {
    if (checkouts.length > 0) return
    if (backCheckouts.some((id) => ids.includes(id))) {
      setBackCheckouts(backCheckouts.filter((i) => !ids.includes(i)))
    } else {
      setBackCheckouts([...backCheckouts, ...ids])
    }
  }

  const onExchange = (key: string) => {
    const formValue: Partial<OrderRequestInventoryModel> = orderForm.watch(`inventoryMap.${key}`)
    const ids: number[] =
      Object.keys(orderForm.watch("inventoryMap")).map((key) => orderForm.watch(`inventoryMap.${key}.inventory.id`)) ||
      []
    const onSelect = (inventory: InventoryModel) => {
      orderForm.setValue(`inventoryMap.${key}`, {
        ...formValue,
        inventory,
        unavailable: false,
        unavailables: []
      })
      modalService.closeModal()
    }
    const params = {
      group: formValue.inventory.group,
      start_at: orderForm.watch("rent_start"),
      end_at: orderForm.watch("rent_end"),
      exclude_ids: ids.join(",")
    }
    modalService.open({
      props: {size: "xl"},
      component: <OrderDetailInventoryListComponent filter={params} onSelect={onSelect} />
    })
  }

  const updateForm = (_order: DetailOrderRequestModel, _inventories: OrderRequestInventoryModel[]) => {
    const setInventories = _inventories.filter((inventory) => !!inventory.set)
    let groupedSets = {}

    for (let i = 0, len = setInventories.length, p; i < len; i++) {
      p = setInventories[i]
      if (groupedSets[p.set] === undefined) groupedSets[p.set] = {}
      if (groupedSets[p.set][p.set_group] === undefined) groupedSets[p.set][p.set_group] = []
      groupedSets[p.set][p.set_group].push(p)
    }

    orderForm.reset({
      rental_point: _order.rental_point,
      client: _order.client,
      rent_start: _order.rent_start,
      rent_fact_start: _order.rent_fact_start,
      rent_end: _order.rent_end,
      rent_fact_end: _order.rent_fact_end,
      discount: _order.discount,
      additional_discount: _order.additional_discount,
      penalty_disabled: _order.penalty_disabled,
      penalty_step_duriation: _order.penalty_step_duriation,
      default_tarif_period: _order.default_tarif_period,
      extra: _order.extra,
      setMap: _order.setMap,
      groupMap: _order.groupMap,
      inventoryMap: _order.inventoryMap,
      inventoryGroupMap: _order.inventoryGroupMap,
      inventorySetMap: _order.inventorySetMap,
      services: _order.services.map((obj) => ({
        object_id: obj.id,
        service: obj.service,
        price: obj.price,
        price_discount: obj.price_discount,
        tarif_price: obj.tarif_price,
        start_at: obj.start_at || _order.rent_start,
        end_at: obj.end_at || _order.rent_end,
        worker: obj.worker && obj.worker.id,
        tarif: obj.tarif,
        info: obj.info,
        extra: obj.extra
      })),
      create_services: []
    })

    const times = new Set<string>([
      _order.rent_start,
      _order.rent_end,
      ..._inventories.reduce((p, c) => [...p, c.start_at, c.end_at], []),
      ..._order.services.reduce((p, c) => [...p, c.start_at, c.end_at], [])
    ])

    if (times.size === 2) setFlexOrder(false)
    if (times.size > 2) setFlexOrder(true)
  }

  const inventoriesIds: number[] =
    formValue && formValue.inventoryMap
      ? Object.keys(formValue.inventoryMap)
          .filter((key) => !!formValue.inventoryMap[key].inventory)
          .map((key) => formValue.inventoryMap[key].inventory.id)
      : []

  const getDeliveries = async () => {
    const list = await orderInventoryService.listDeliveries(+id)
    setDeliveries(list)
  }

  const updateOrder = async () => {
    setCheckouts([])
    setBackCheckouts([])

    const [_order, _inventories, _groups, _sets] = await Promise.all([
      firstValueFrom(orderService.get(+id)),
      orderInventoryService.listInventories(+id),
      orderInventoryService.listGroups(+id),
      orderInventoryService.listSets(+id),
      getDeliveries()
    ])

    const inventoryMap = Object.fromEntries(
      _inventories.map((t) => [t.inventory ? `${t.id}-${t.inventory.id}` : String(t.id), t])
    )
    const inventoryGroupMap = Object.fromEntries(
      _groups.map((g) => [
        g.id,
        _inventories
          .filter((inv) => (inv.inventory_group || inv.inventory.group) === g.id && !inv.set)
          .map((t) => (t.inventory ? `${t.id}-${t.inventory.id}` : String(t.id)))
      ])
    )
    const invSet = new Set(
      _sets.reduce(
        (p, s) => [...p, ..._inventories.filter((i) => i.set === s.id).map((i) => `${s.id}-${i.set_group}`)],
        []
      )
    )
    const inventorySetMap = Object.fromEntries(
      [...invSet.values()].map((key) => {
        const [setId, setGroupKey] = key.split("-")
        return [
          key,
          _inventories
            .filter((i) => i.set === +setId && i.set_group === setGroupKey)
            .map((i) => `${i.id}-${i.inventory.id}`)
        ]
      })
    )

    _order.setMap = Object.fromEntries(_sets.map((s) => [s.id, s]))
    _order.groupMap = Object.fromEntries(_groups.map((g) => [g.id, g]))
    _order.inventoryMap = inventoryMap

    _order.inventorySetMap = inventorySetMap
    _order.inventoryGroupMap = inventoryGroupMap

    const values = [
      _order.rent_start,
      _order.rent_end,
      _order.rent_fact_start,
      _order.rent_fact_end,
      ..._inventories.map((inv) => inv.start_at),
      ..._inventories.map((inv_1) => inv_1.end_at),
      ..._inventories.map((inv_2) => inv_2.fact_start_at),
      ..._inventories.map((inv_3) => inv_3.fact_end_at)
    ].filter((time) => !!time)

    const [minDate, maxDate] = getMinMaxDate(values)
    _order.minStart = minDate
    _order.maxEnd = maxDate
    setOrder(_order)
    setInventories(_inventories)

    updateForm(_order, _inventories)
    await checkUnavailable()
    return _order
  }

  const updateInventories = async () => {
    const _inventories = await orderInventoryService.listInventories(+id)
    const inventoryMap = Object.fromEntries(
      _inventories.filter((i) => !!i.inventory).map((t) => [`${t.id}-${t.inventory.id}`, t])
    )
    const keys = Object.keys(inventoryMap)

    const inventoryGroupMap = orderForm.watch("inventoryGroupMap")
    const groupMap = Object.keys(inventoryGroupMap).map((key) => ({
      key,
      list: inventoryGroupMap[key].filter((str) => keys.includes(str))
    }))

    const inventorySetMap = orderForm.watch("inventorySetMap")
    const setMap = Object.keys(inventorySetMap).map((key) => ({
      key,
      list: inventorySetMap[key].filter((str) => keys.includes(str))
    }))

    orderForm.setValue("inventorySetMap", Object.fromEntries(setMap.map((obj) => [obj.key, obj.list])))
    orderForm.setValue("inventoryGroupMap", Object.fromEntries(groupMap.map((obj) => [obj.key, obj.list])))
    orderForm.setValue("inventoryMap", inventoryMap)
  }

  const partialSubmit = async (payload: any) => {
    await orderService.patch(order.id, payload)
    orderDocumentService.listDocuments(order.id)
  }

  const onSubmit = orderForm.handleSubmit(
    async (payload) => {
      setExtraModal({show: false, keys: []})
      if ("rental_point" in payload && isNil(payload.rental_point)) {
        modalService.open({
          component: (
            <ConfirmModal
              title="Выберите точку проката"
              message="В аренде не выбрана точка проката, выберите точку проката"
              onConfirm={() => modalService.closeModal()}
            />
          )
        })
      }

      const orderInventories = Object.fromEntries(inventories.map((form) => [form.id, form]))
      const inventoryKeys = Object.keys(payload.inventoryMap)

      const creatingInventoryKeys = inventoryKeys.filter(
        (key) => Number.isNaN(+key.split("-")[0]) && !isNil(payload.inventoryMap[key])
      )
      const updatingInventoryKeys = inventoryKeys.filter(
        (key) => !Number.isNaN(+key.split("-")[0]) && !isNil(payload.inventoryMap[key])
      )

      const creatingInventories = creatingInventoryKeys.map((key) => {
        const obj = payload.inventoryMap[key]
        return {
          set: obj.set || null,
          set_group: obj.set_group || null,
          inventory_group: obj.inventory_group || obj.inventory.group,
          inventory: obj.inventory ? obj.inventory.id : null,
          tarif: obj.tarif,
          tarif_price: obj.tarif_price,
          tarif_duration: obj.tarif_duration,
          discount: obj.discount,
          additional_discount: obj.additional_discount,
          start_at: obj.start_at,
          end_at: obj.end_at,
          type: obj.type
        }
      })

      const updatingInventories = updatingInventoryKeys
        .map((key) => {
          const obj = payload.inventoryMap[key]
          return {
            id: obj.id,
            set: obj.set,
            set_group: obj.set_group,
            inventory_group: obj.inventory_group || obj.inventory.group,
            inventory: obj.inventory ? obj.inventory.id : null,
            tarif: obj.tarif,
            tarif_price: obj.tarif_price,
            tarif_duration: obj.tarif_duration,
            discount: obj.discount,
            additional_discount: obj.additional_discount,
            start_at: obj.start_at,
            end_at: obj.end_at,
            type: obj.type
          }
        })
        .filter((obj) => {
          const form = orderInventories[obj.id]
          if (!form) return false
          const form_obj = {
            id: form.id,
            set: form.set,
            set_group: form.set_group,
            inventory: form.inventory ? form.inventory.id : null,
            tarif: form.tarif,
            tarif_price: form.tarif_price,
            tarif_duration: form.tarif_duration,
            discount: form.discount,
            additional_discount: form.additional_discount,
            start_at: form.start_at,
            end_at: form.end_at
          }
          return detectChanges(obj, form_obj) === false
        })

      const updateServices = detectServiceChange(order.services, payload.services)

      const createServices = payload.create_services.map((obj) => ({
        service: obj.service.id,
        tarif: obj.tarif,
        worker: obj.worker,
        info: obj.info,
        extra: obj.extra,
        start_at: obj.start_at,
        end_at: obj.end_at
      }))

      const updateServicesRequests = updateServices.map((payload) =>
        orderRequestServiceService.patch(order.id, payload.id, payload)
      )

      const createServiceRequests = createServices.map((payload) => orderRequestServiceService.post(order.id, payload))

      const orderPayload = {
        client: payload.client,
        rent_start: payload.rent_start,
        rent_end: payload.rent_end,
        default_tarif_period: payload.default_tarif_period || null,
        discount: payload.discount || null,
        additional_discount: payload.additional_discount || 0,
        penalty_disabled: payload.penalty_disabled || false,
        penalty_step_duriation: payload.penalty_step_duriation,
        rental_point: payload.rental_point,
        extra: payload.extra
      }

      const promises: Array<Promise<any>> = [...updateServicesRequests, ...createServiceRequests]

      const handleInventoryError = (error) => {
        const data = error.response.data

        if ("error" in data && "inventories" in data && "type" in data) {
          const inventoryError: OrderRequestInventoryError = data
          modalService.open({
            props: {size: "lg"},
            component: <InventoryError inventoryError={inventoryError} />
          })
        }
      }

      if (creatingInventories.length > 0) {
        promises.push(
          orderInventoryService.bulkCreate(order.id, {inventories: creatingInventories}).catch(handleInventoryError)
        )
      }

      if (updatingInventories.length > 0) {
        promises.push(
          orderInventoryService.bulkUpdate(order.id, {inventories: updatingInventories}).catch(handleInventoryError)
        )
      }

      await Promise.all(promises)
      await orderService.patch(order.id, orderPayload)
      await updateOrder()
    },
    (error) => {
      if ("extra" in error) setExtraModal({show: true, keys: Object.keys(error.extra)})
    }
  )

  const onInventorySelect = async (
    payload: any,
    onAdd: (inventories: Partial<OrderRequestInventoryModel>[]) => any
  ) => {
    return orderInventoryService.postGroup(order.id, payload).then((inventories) => {
      onAdd(inventories)
      modalService.closeModal()
      return inventories.filter((inv) => !!inv.inventory).map((inv) => inv.inventory.id)
    })
  }

  const onGroup = async (group: InventoryGroupModel, count: number, filter: any): Promise<number[]> => {
    const payload = {
      count,
      filter,
      group: group.id,
      time_period: orderForm.watch("default_tarif_period"),
      start_at: orderForm.watch("rent_start"),
      end_at: orderForm.watch("rent_end"),
      exclude_ids: inventoriesIds
    }

    return onInventorySelect(payload, (inventories: Partial<OrderRequestInventoryModel>[]) => {
      const payloads = inventories.reduce((prev, form) => {
        const key = keygen("key_")
        form.inventory = settings.order_inventory_occupation ? form.inventory : null
        const k = form.inventory ? `inventoryMap.${key}-${form.inventory.id}` : `inventoryMap.${key}`
        const v = {
          id: null,
          set: null,
          set_group: null,
          inventory: null,
          inventory_group: group.id,
          ...form
        }
        return {...prev, [k]: v}
      }, {})

      orderForm.setValue(`groupMap.${group.id}`, group)
      orderForm.setValue(`inventoryGroupMap.${group.id}`, [
        ...(orderForm.watch(`inventoryGroupMap.${group.id}`) || []),
        ...Object.keys(payloads).map((obj) => obj.replace("inventoryMap.", ""))
      ])
      Object.keys(payloads).forEach((key) => orderForm.setValue(key as any, payloads[key]))
    })
  }

  const onSelect = async (inventory: InventoryModel) => {
    const payload = {
      group: inventory.group,
      time_period: orderForm.watch("default_tarif_period"),
      start_at: orderForm.watch("rent_start"),
      end_at: orderForm.watch("rent_end"),
      exclude_ids: inventoriesIds,
      inventory_unique_id: inventory.unique_id
    }

    return onInventorySelect(payload, async (inventories: Partial<OrderRequestInventoryModel>[]) => {
      const key = keygen("key_")

      const payloads = inventories.reduce((prev, form) => {
        form.inventory = settings.order_inventory_occupation ? form.inventory : null
        const k = form.inventory ? `inventoryMap.${key}-${form.inventory.id}` : `inventoryMap.${key}`
        const v = {
          id: null,
          set: null,
          inventory: null,
          inventory_group: form.inventory?.group,
          set_group: null,
          ...form
        }
        return {...prev, [k]: v}
      }, {})

      const group = Object.keys(orderForm.watch("inventoryGroupMap")).find((k) => +k === inventory.group)

      if (!group) {
        const group = await inventoryGroupService.get(inventory.group)
        orderForm.setValue(`groupMap.${inventory.group}`, group)
        orderForm.setValue(`inventoryGroupMap.${inventory.group}`, [`${key}-${inventory.id}`])
      } else {
        const keys = orderForm.watch(`inventoryGroupMap.${inventory.group}`)
        orderForm.setValue(`inventoryGroupMap.${inventory.group}`, [...keys, `${key}-${inventory.id}`])
      }

      Object.keys(payloads).forEach((key) => orderForm.setValue(key as any, payloads[key]))
    })
  }

  const checkUnavailable = async () => {
    const value = orderForm.watch()
    if (value.rent_start && value.rent_end) {
      const ids = Object.keys(value.inventoryMap).map((key) => key.split("-")[1])
      const start_at = orderForm.watch("rent_start")
      const end_at = orderForm.watch("rent_end")
      const params = {
        start_at,
        end_at,
        id__in: ids.filter((k) => !!k).join(",")
      }

      inventoryService.listUnavailables(params).then((schedule) => {
        const inventorySchedule = Object.fromEntries(
          ids.map((id) => [id, schedule.filter((s) => s.inventory === Number(id))])
        )

        Object.keys(value.inventoryMap).forEach((key) => {
          const id = key.split("-")[1]
          const form = value.inventoryMap[key]
          const unavailables = inventorySchedule[id].filter((val) => {
            const _1 = order && val.request_id !== order.id
            const _2 = checkTimeRange(
              val.start_at,
              val.end_at,
              form.fact_start_at || form.start_at,
              form.fact_start_at || form.end_at
            )
            return _1 && _2
          })
          orderForm.setValue(`inventoryMap.${key}`, {
            ...form,
            unavailable: unavailables.length > 0,
            unavailables
          })
        })
      })
    }
  }

  const disableSave = orderForm.formState.isValid

  const onInventorySetFilter = async (item: InventorySetModel, count = 1): Promise<number[] | void> => {
    const onSubmit = async (filter: {list: Array<{count: number; filter: Record<number, any>}>}): Promise<number[]> => {
      const payload = {
        set: item.id,
        time_period: orderForm.watch("default_tarif_period"),
        start_at: orderForm.watch("rent_start"),
        end_at: orderForm.watch("rent_end"),
        filters: filter.list,
        exclude_ids: Object.keys(orderForm.watch("inventoryMap")).map((key) => +key.split("-")[1])
      }

      return orderInventoryService.postSet(order.id, payload).then((inventories) => {
        const payloads = inventories.reduce((prev, form) => {
          const k = `inventoryMap.${form.set_group}-${form.inventory.id}`
          const v = {
            id: null,
            set: item.id,
            type: 0,
            inventory: null,
            inventory_group: form.inventory.group,
            ...form
          }
          return {...prev, [k]: v}
        }, {})

        const setGroups = [...new Set(inventories.map((i) => i.set_group))]

        orderForm.setValue(`setMap.${item.id}`, item)

        setGroups.forEach((key) => {
          const keys = orderForm.watch(`inventorySetMap.${item.id}-${key}`) || []
          orderForm.setValue(`inventorySetMap.${item.id}-${key}`, [
            ...keys,
            ...inventories.filter((i) => i.set_group === key).map((form) => `${key}-${form.inventory.id}`)
          ])
          Object.keys(payloads).forEach((key) => orderForm.setValue(key as any, payloads[key]))
        })

        if (filter.list.some((obj) => Object.keys(obj.filter).length > 0)) modalService.closeModal()

        return inventories.map((obj) => obj.inventory.id)
      })
    }

    if (settings && settings.custom_fields.inventory.every((f) => f.filter === false)) {
      return onSubmit({list: [{count, filter: {}}]})
    }

    if (settings && settings.custom_fields.inventory.some((f) => f.filter)) {
      modalService.closeModal()
      modalService.open({
        props: {size: "xl", backdrop: true},
        component: <OrderDetailSetFilterComponent set={item} count={count} onSubmit={onSubmit} />
      })
    }
  }

  useEffect(() => {
    updateOrder()
    return () => setOrder(null)
  }, [id])

  useEffect(() => {
    checkUnavailable()
  }, [orderForm.watch("rent_start"), orderForm.watch("rent_end")])

  useEffect(() => {
    if (!tenant) return
    const timeLeft = moment.duration(moment(tenant.end_at).valueOf() - moment.now(), "milliseconds").as("seconds")
    if (timeLeft < 0) return

    // newSubsription(`orderrequest:${tenant.id}-${id}`, `/v1/crm/requests/${id}/connect/`, (ctx) => updateOrder())
    newSubsription(`document:${tenant.id}`, "/v1/documents/connect/", (ctx) => {
      orderDocumentService.listDocuments(+id)
      if (!ctx) return
      if (!ctx.data) return
      if (!ctx.data.signed) return
      orderDocumentService.indicateDocumentSign(Number(ctx.data.signed))
    })
    return () => {
      unsubsribe(`orderrequest:${tenant.id}-${id}`)
      unsubsribe(`document:${tenant.id}`)
    }
  }, [id, tenant])

  useEffect(() => {
    orderPaymentService.listPayments(+id)
    const sub = orderPaymentService.payments$.subscribe(setPayments)
    return () => sub.unsubscribe()
  }, [id])

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        inventories,
        deliveries,
        payments,
        disableSave,
        flexOrder,
        setFlexOrder,
        checkouts,
        backCheckouts,
        addCheckout,
        addCheckouts,
        addBackCheckout,
        addBackCheckouts,
        orderForm,
        servicesForm,
        createServicesForm,
        updateOrder,
        updateInventories,
        onSubmit,
        partialSubmit,
        prematureStart,
        setPrematureStart,
        inventoriesIds,
        checkUnavailable,
        onExchange,
        onGroup,
        onSelect,
        onInventorySetFilter,
        getDeliveries
      }}>
      <FormProvider {...orderForm}>
        {order && children}
        <CustomModal
          backdropClick={true}
          show={extraModal.show}
          onHide={() => setExtraModal((p) => ({...p, show: !p.show}))}>
          <div className="text-xl font-semibold">Введите данные</div>
          <CustomFieldChoiceList type="order" className="my-3" newField={false} keys={extraModal.keys} />
          <div className="flex justify-end">
            <button className="btn btn-primary btn-color-white font-semibold" onClick={onSubmit}>
              {t("common.save")}
            </button>
          </div>
        </CustomModal>
      </FormProvider>
    </OrderContext.Provider>
  )
}
