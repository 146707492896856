import {useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {endOfMonth, startOfMonth} from "src/components/shared/constants"
import {UserSalaryDetailModel, UserSalaryType, salaryNameMap} from "src/models/manager/profile/statistics"
import {toPrice} from "src/utils/price"
import ProfileRentReportComponent from "./profile-rent-report"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"
import metricsService from "src/services/metrics/products-parts.service"
import Select from "react-select"
import {colourStyles} from "src/components/shared/inputs/form-select-color-style"
import {useTranslation} from "react-i18next"
import Icon from "src/components/shared/components/material-icon"
import metricsExportService from "src/services/metrics/export.service"
import toDate from "src/utils/date"

interface OptionParams {
  value: UserSalaryType
  label: string
}

export default function ProfileMetricsComponent(config: {
  userId: number
  start_at?: string
  end_at?: string
  header?: JSX.Element
}) {
  const {t} = useTranslation()
  const [stat, setStat] = useState<UserSalaryDetailModel>()

  const form = useForm<any>({
    defaultValues: {
      type: undefined,
      ordering: undefined,
      start_at: config.start_at || startOfMonth.format("YYYY-MM-DD"),
      end_at: config.end_at || endOfMonth.format("YYYY-MM-DD"),
      page: 1,
      pageSize: 10
    }
  })
  const values = form.watch()
  const tab = values.type
  const loadStat = (_params) => metricsService.getUserSalaryDetail(config.userId, _params).then(setStat)

  const onExcel = form.handleSubmit(async (params) => {
    await metricsExportService.uploadUserSalaryTypeEarningList(
      `${t("profile.activity")} №${config.userId} (${toDate(params.start_at)} - ${toDate(params.end_at)})`,
      config.userId,
      params
    )
  })

  useEffect(() => {
    loadStat(values)
    const sub = form.watch(loadStat)
    return () => sub.unsubscribe()
  }, [])

  const options: OptionParams[] = [
    {value: "creations", label: t(salaryNameMap["creations"])},
    {value: "reservations", label: t(salaryNameMap["reservations"])},
    {value: "issues", label: t(salaryNameMap["issues"])},
    {value: "receivements", label: t(salaryNameMap["receivements"])}
  ]

  return (
    <FormProvider {...form}>
      {config.header}

      <div className="flex items-center justify-between gap-2 mb-3">
        <div className="flex items-center gap-2">
          <FormControlDaterange className="form-control" name_start="start_at" name_end="end_at" />

          <Select
            {...config}
            name="key"
            className="col"
            isClearable
            isSearchable={false}
            placeholder={t("settings.user_actions.selector")}
            styles={colourStyles(false)}
            onChange={(val: OptionParams) => form.setValue("type", val?.value)}
            options={options}
          />
        </div>

        <div className="btn btn-card" onClick={onExcel}>
          <Icon icon="download" />
          {t("common.download_excel")}
        </div>
      </div>

      {stat && (
        <div className="card-bio bg-white flex flex-row mb-3" style={{width: "max-content", borderRadius: 12}}>
          <div className="flex flex-col mx-1 border-r border-gray-100 pr-5">
            <div className="font-bold text-lg mb-2">
              {tab
                ? stat[tab].count
                : stat.creations.count + stat.reservations.count + stat.issues.count + stat.receivements.count}
            </div>
            <label className="text-sm text-gray-400">{t("profile.stat.count")}</label>
          </div>
          <div className="flex flex-col mx-1 border-r border-gray-100 pr-5">
            <div className="font-bold text-lg mb-2">
              {toPrice(
                tab
                  ? stat[tab].amount
                  : +stat.creations.amount + +stat.reservations.amount + +stat.issues.amount + +stat.receivements.amount
              )}
            </div>
            <label className="text-sm text-gray-400">{t("profile.stat.amount")}</label>
          </div>
          <div className="flex flex-col mx-1">
            <div className="font-bold text-lg mb-2">
              {toPrice(
                tab
                  ? stat[tab].earning
                  : +stat.creations.earning +
                      +stat.reservations.earning +
                      +stat.issues.earning +
                      +stat.receivements.earning
              )}
            </div>
            <label className="text-sm text-gray-400">{t("profile.stat.earned")}</label>
          </div>
        </div>
      )}

      <ProfileRentReportComponent userId={config.userId} />
    </FormProvider>
  )
}
