import React, {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import Table from "src/components/shared/antd-custom/table"
import {FormProvider, useForm} from "react-hook-form"
import Icon from "src/components/shared/components/material-icon"
import {FormControl} from "src/components/shared/inputs/form-control"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {Transfer} from "src/models/manager/transfer/transfer"
import metricsService from "src/services/metrics/products-parts.service"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import type {ColumnsType} from "antd/es/table"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"
import {useDebounce} from "src/hooks/useDebounce"
import metricsExportService from "src/services/metrics/export.service"
import toDate from "src/utils/date"
import modalService from "src/components/modal/global/modal.service"
import transportationService from "../../../services/transportation.service"
import {map} from "rxjs"
import TransferAgent from "src/components/shared/components/select/transfer-agent"
import RideMetricsModal from "./modal"

const ReportRidesTableComponent = ({form}: {form: any}) => {
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  const [list, setList] = useState<ListModel<Transfer>>(EMPTY_LIST)
  const [agentMap, setAgentMap] = useState<Record<number, any>>({})

  const {watch, setValue, reset, handleSubmit} = form
  const values = watch()
  const [isOpen, setIsOpen] = useState<{[key: number]: boolean}>({})
  const searchDebounce = useDebounce(watch("search"), 500)

  const toggleRow = (id: number) => {
    setIsOpen((prev) => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  const listRides = (params: any) => {
    metricsService
      .listRidesList(params)
      .then(setList)
      .catch((e) => e.response.status === 404 && setValue("page", 1))
  }

  const onExcel = handleSubmit(
    async (params) =>
      await metricsExportService.uploadTransactions(
        `${t("reports.sales.finance.transactions")} (${toDate(params.start_at)} - ${toDate(params.end_at)})`,
        params
      )
  )

  const columns = [
    {
      width: 44,
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (id) => id
    },
    {
      width: 148,
      title: t("metrics.finance.transactions.client_name"),
      dataIndex: "client",
      key: "client",
      render: (_, {client}) => client && client.name
    },
    {
      width: 64,
      title: t("metrics.finance.transactions.created_at"),
      key: "created_at",
      dataIndex: "created_at",
      render: (created_at) => toDateTime(created_at),
      sorter: true
    },
    {
      width: 148,
      title: t("transfers.main.driver"),
      key: "driver_user_name",
      dataIndex: "driver_user_name",
      render: (driver_user_name) => driver_user_name,
      sorter: true
    },
    {
      width: 44,
      title: t("metrics.finance.transactions.amount"),
      key: "price",
      dataIndex: "price",
      render: (price) => toPrice(price),
      sorter: true
    },
    {
      width: 224,
      title: t("transfers.form.customer"),
      dataIndex: "agent",
      key: "agent",
      render: (_, {agent}) =>
        agent && (
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-sm">{agentMap[agent]?.name}</div>
          </div>
        ),
      sorter: true
    }
  ]

  const mobileColumns: ColumnsType<any> = [
    {
      dataIndex: "id",
      key: "id",
      width: "auto",
      render: (_, {id, client_name, client_id, price, created_at, driver_user_name}) => {
        const isRowOpen = !!isOpen[id]

        return (
          <div className="flex flex-col gap-4" onClick={() => toggleRow(id)}>
            <div className="flex justify-between items-center gap-4 p-1">
              <div className="flex flex-col gap-2 border-gray-200">
                <div className="flex gap-2 font-medium text-gray-700">
                  <span>№ {id}</span>
                  <span> · </span>
                  <span className="text-gray-800 font-semibold">
                    {client_name && client_name} №{client_id}
                  </span>
                </div>

                <span className="font-medium text-gray-400">
                  {created_at && toDateTime(created_at)} · {toPrice(+price)}
                </span>
              </div>

              <Icon className={isRowOpen ? "!rotate-180" : "!rotate-0"} icon="keyboard_arrow_up" />
            </div>

            {isRowOpen && (
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-end justify-between">
                  <span className="text-sm text-gray-400">{t("transfers.main.driver")}</span>
                  <span className="flex-1 border-b-2 border-dashed border-gray-100"></span>
                  <span className="text-sm text-gray-400">{driver_user_name}</span>
                </div>
              </div>
            )}
          </div>
        )
      }
    }
  ]

  const onShowModal = () => {
    modalService.open({
      props: {size: "lg"},
      component: (
        <div className="min-h-[370px]">
          <RideMetricsModal form={form} />
        </div>
      )
    })
  }

  useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

  useEffect(() => {
    listRides(values)
    const sub = watch(listRides)
    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  useEffect(() => {
    const sub = transportationService.agents$
      .pipe(map((list) => list.reduce((p, c) => ({...p, [c.id]: c}), {})))
      .subscribe(setAgentMap)
    return () => sub.unsubscribe()
  }, [])

  return (
    <FormProvider {...form}>
      <Table
        title={() => (
          <div className="flex md:flex-row flex-col flex-wrap md:items-center items-start md:gap-2 gap-4 justify-between">
            <div className="flex items-center justify-between md:justify-start gap-3 w-full">
              {/*<div className="flex md:hidden gap-2 items-center" onClick={onExcel}>*/}
              {/*  <Icon icon="download" className="text-primary text-[22px]" />*/}
              {/*  <p className="text-[15px] font-medium text-primary">{t("common.export")}</p>*/}
              {/*</div>*/}
            </div>
            <div className="flex gap-4 w-full md:w-max">
              <FormControl
                icon="search"
                rootclassname="col"
                className="form-control"
                name="search"
                placeholder={t("common.input.search")}
              />
              {!isMobile && (
                <TransferAgent
                  className="col"
                  placeholder={t("transfers.form.customer")}
                  name="transfer__agent"
                  isClearable={true}
                  isSearchable={false}
                  required
                />
              )}
              {isMobile && (
                <div
                  className="min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center"
                  onClick={onShowModal}>
                  <Icon icon="filter_list" className="h-5 w-6 text-2xl" />
                </div>
              )}
            </div>
          </div>
        )}
        rowKey={(obj) => obj.id}
        columns={isMobile ? mobileColumns : columns}
        dataSource={list.results}
        autoScroll={isMobile ? false : true}
        showHeader={!isMobile}
        className={isMobile && "!rounded-0 !border-0"}
        rootClassName="w-full"
      />
      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}

export default ReportRidesTableComponent
