import {ActionMeta, GroupBase, OptionsOrGroups, Props, components} from "react-select"
import {useFormContext, Controller, RegisterOptions, FieldError} from "react-hook-form"
import {colourStyles} from "./form-select-color-style"
import {CSSProperties, useEffect, useState} from "react"
import {ListModel} from "src/models/common"
import {isNil} from "src/utils/isNil"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {AsyncPaginate} from "react-select-async-paginate"
import {SelectMenu, SelectOption} from "./select/common"
import {Observable} from "rxjs"

export interface FormSelectAsyncConfig<T> extends Props {
  label?: string
  className?: string

  name: string
  params?: RegisterOptions<any, any>
  listOptions: (params: any) => Promise<ListModel<T | T[]>>
  getValue: (id: number | number[]) => Promise<T | T[]>
  style?: CSSProperties
  modalProps?: ExtendedModalConfig
  disabled?: boolean
  onCreate?: Observable<void>
}

export default function FormSelectAsyncPagination<T>(config: FormSelectAsyncConfig<T>) {
  const [key, setKey] = useState(0)
  const {
    control,
    watch,
    formState: {errors}
  } = useFormContext()
  const [selectedOption, setSelectedOption] = useState<T | T[]>(null)
  const onModal = config.modalProps ? () => modalService.open(config.modalProps) : undefined

  useEffect(() => {
    if (watch(config.name)) {
      config.getValue(watch(config.name)).then(setSelectedOption)
    }
  }, [watch(config.name)])

  const loadOptions = async (
    search: string,
    prevOptions: OptionsOrGroups<T, GroupBase<T>>,
    {page = 1}: {page: number}
  ) => {
    const response = await config.listOptions({search, page, skip_loader: true})
    return {
      options: response.results,
      hasMore: !isNil(response.next),
      additional: {page: page + 1}
    }
  }

  useEffect(() => {
    if (!config.onCreate) return
    const sub = config.onCreate.subscribe(() => setKey((k) => k + 1))
    return () => sub.unsubscribe()
  }, [config.onCreate])

  return (
    <div className={config.className} style={config.style}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] text-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <div className="flex gap-2">
        <Controller
          name={config.name}
          control={control}
          rules={config.params}
          render={({field: {onBlur, value, onChange, ref}, fieldState}) => (
            <AsyncPaginate
              key={key}
              styles={colourStyles(!isNil(fieldState.error))}
              value={value ? selectedOption : null}
              loadOptions={loadOptions}
              additional={{page: 1}}
              debounceTimeout={500}
              onBlur={onBlur}
              isSearchable={false}
              isClearable
              {...config}
              onChange={(val: T | T[], action: ActionMeta<T | T[]>) => {
                if (Array.isArray(val)) {
                  setSelectedOption(val.length > 0 ? val : null)
                  onChange(val.map((obj) => config.getOptionValue(obj)))
                } else {
                  setSelectedOption(val)
                  onChange(config.getOptionValue(val))
                }
                if (!isNil(config.onChange)) config.onChange(val, action)
              }}
              noOptionsMessage={() => "пусто"}
              loadingMessage={() => "загрузка..."}
              selectRef={ref}
              isDisabled={config.disabled}
              components={{
                Menu: !isNil(onModal)
                  ? (params) => <SelectMenu {...params} onModal={onModal} modalProps={config.modalProps} />
                  : components.Menu,
                Option: (params) => <SelectOption {...params} />,
                ...config.components
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  danger: "rgb(248, 52, 52)",
                  dangerLight: "rgb(248, 52, 52)",
                  primary: "rgb(161, 101, 253, 1)",
                  primary25: "rgb(161, 101, 253, 0.08)",
                  primary50: "rgb(161, 101, 253, 0.20)",
                  primary75: "rgb(161, 101, 253, 0.8)"
                }
              })}
            />
          )}
        />
      </div>
      {errors[config.name] && <p className="text-alert mt-1 text-sm">{(errors[config.name] as FieldError)?.message}</p>}
    </div>
  )
}
