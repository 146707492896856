import {useContext, useMemo} from "react"
import {ConfigContext} from "src/app"
import {useTranslation} from "react-i18next"
import toDate from "../../../utils/date"
import subscriptionModalService from "../../../services/subscription.service"
import tenantService from "../../../services/tenant.service"
import {priceBeautify, toPrice} from "../../../utils/price"
import moment from "moment/moment"
import {TariffPermissionList} from "./tariff-permission-list"

export default function CurrentTariffCard() {
  const {tenant} = useContext(ConfigContext)
  const {subscription, start_at, end_at, period} = tenant
  const {t} = useTranslation()
  const daysLeft = useMemo(() => tenant && moment(tenant.end_at).diff(moment(), "days"), [tenant])

  const totalPeriod = useMemo(() => {
    if (!tenant) return null
    if (!period) return null
    return `${period.months} ${t("common.times_abb.month")} ${period.days} ${t("common.times_abb.day")}`
  }, [tenant])
  const onSubscriptionPayment = async () => {
    const ret = await tenantService.createInvoice({
      subscription: subscription.id,
      period: period.id,
      success_url: window.location.href,
      extra: {}
    })
    if (ret.invoice && ret.invoice.extra && +ret.invoice.status !== 3 && "wooppay_link" in ret.invoice.extra) {
      window.location.replace(ret.invoice.extra.wooppay_link)
    } else {
      window.location.reload()
    }
  }
  return (
    period &&
    subscription && (
      <div className="flex flex-col gap-6 w-full p-6 bg-white card md:shadow md:border-gray-100">
        <div className="flex flex-col gap-2">
          <p className="font-bold text-[32px] text-black">{subscription.name}</p>
          <p className="font-normal text-[13px] text-gray-400">Текущий тариф</p>
        </div>

        <div className="flex gap-3 w-full justify-between">
          <div className="grid grid-cols-2 gap-y-3 row justify-between flex-wrap w-4/5">
            <TariffPermissionList {...tenant} />
          </div>
          {/* <div className="flex flex-col gap-4 w-1/5 font-medium text-[13px]">
            <button
              className="btn btn-primary btn-color-white h-12 max-w-[223px]"
              disabled={daysLeft > 3}
              onClick={onSubscriptionPayment}>
              Оплатить
            </button>
            <button
              onClick={() => subscriptionModalService.setShow({show: true, type: null})}
              className="btn bg-gray-70 hover:bg-gray-100 h-12 max-w-[223px]">
              Сменить тариф
            </button>
          </div> */}
        </div>

        <hr className="text-gray-300 w-full" />

        <div className="flex gap-12 text-[13px]">
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Дата приобретения</p>
            <p className="font-medium text-black">{toDate(start_at)}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Действительно до</p>
            <p className="font-medium text-black">{toDate(end_at)}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Период подписки</p>
            <p className="font-medium text-black">{totalPeriod}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-gray-400">Оплаченная сумма</p>
            <p className="font-medium text-black">{priceBeautify(period.price, 0) + " " + period.currency}</p>
          </div>
          {/*<div className="flex flex-col gap-2">*/}
          {/*  <p className="font-normal text-gray-400">Способ оплаты</p>*/}
          {/*  <p className="font-medium text-black"></p>*/}
          {/*</div>*/}
        </div>

        {/*<div className="flex gap-2 text-gray-350 text-xl items-center">*/}
        {/*  <Icon icon="info" className="text-xl text-gray-350" />*/}
        {/*  <p className="font-normal text-[13px] text-gray-350">Есть автоматическое продление тарифа</p>*/}
        {/*</div>*/}
      </div>
    )
  )
}
