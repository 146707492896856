import Table from "src/components/shared/antd-custom/table"
import type {ColumnsType} from "antd/es/table"
import {MouseEvent, useContext, useEffect, useMemo, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import modalService from "src/components/modal/global/modal.service"
import {ImageField} from "src/components/shared/image-loader/image"
import {FormControlEntity} from "src/components/shared/inputs/form-control-entity"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import useQueryParams from "src/hooks/useQuertParams"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {InventoryGeneralModel} from "src/models/manager/inventory/inventory-general.model"
import {InventoryGroupModel, InventoryGroupStateModel} from "src/models/manager/inventory/inventory-group.model"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import inventoriesService from "src/services/inventory/inventory.service"
import toDateTime from "src/utils/date-time"
import {isNil} from "src/utils/isNil"
import objectFilter from "src/utils/object.filter"
import CardWrapper from "src/components/shared/components/card"
import Icon from "src/components/shared/components/material-icon"
import {TableRowSelection} from "antd/es/table/interface"
import {ControlCheckbox} from "src/components/shared/inputs/control-checkbox"
import {useTranslation} from "react-i18next"
import InventoryGroupListFilterComponent, {InventoryGroupFilterForm} from "./filter"
import {invertColor} from "src/utils/color/color-invert"
import {ConfigContext} from "src/app"
import {Segmented} from "../../antd-custom"
import {CardSection} from "../card/card-content"
import {TenantType} from "src/services/tenant.service"

function InventoryGroupAddCell({
  group,
  onSelect,
  _default = false
}: {
  group: InventoryGroupModel
  onSelect: (group: InventoryGroupModel, count?: number) => Promise<any>
  _default?: boolean
}) {
  const form = useForm({defaultValues: {count: 1}})
  const {t} = useTranslation()
  const availableCount =
    group.inventories_count - group.inventories_occupied - group.inventories_disabled - group.inventories_overdued
  const disabled = form.watch("count") === 0 || availableCount === 0
  const onSubmit = form.handleSubmit((payload) => onSelect(group, payload.count))
  const {isMobile} = useContext(ConfigContext)
  return (
    <FormProvider {...form}>
      <div className="flex gap-2 align-center">
        {isMobile ? (
          <button
            className={`btn gap-1 px-4 py-3 ${isMobile ? "btn-primary-20 h-9 w-9" : "btn-black"}`}
            disabled={_default === false ? disabled : false}
            onClick={onSubmit}>
            <Icon icon="add" className={"md:text-white text-primary"} />
          </button>
        ) : (
          <>
            <FormControlEntity
              name="count"
              maxcount={availableCount}
              rootclassname="flex justify-center items-center"
            />
            <button
              className="btn btn-primary color-white"
              disabled={_default === false ? disabled : false}
              onClick={onSubmit}>
              {t("common.add")}
            </button>
          </>
        )}
      </div>
    </FormProvider>
  )
}

function InventoryGroupStateComponent({stateId, count}: {stateId: number; count: number}) {
  const {constantsMap} = useContext(ConfigContext)
  const state = useMemo(() => constantsMap.INVENTORY_STATE_STATUS[stateId], [constantsMap, stateId])

  return (
    state &&
    count > 0 && (
      <li className="flex items-center justify-between gap-2">
        <span className="overflow line-clamp-1">{state.name}</span>
        <div
          className="btn py-1 px-2 text-sm font-medium"
          style={{
            backgroundColor: state.color,
            color: invertColor(state.color)
          }}>
          {count}
        </div>
      </li>
    )
  )
}

function InventoryGroupAvailability({group, states}: {group: InventoryGroupModel; states: InventoryGroupStateModel[]}) {
  const {t} = useTranslation()

  return group.type === 0 ? (
    <div className="flex gap-1 flex-col">
      <li className="flex items-center justify-between gap-2">
        <span className="overflow line-clamp-1">{t("groups.table.availability.free")}</span>
        <span className="text-nowrap btn btn-green-8 btn-color-green py-1 px-2 text-sm font-medium btn-icon">
          {group.inventories_count -
            Math.max(group.inventories_occupied, group.inventories_overdued) -
            group.inventories_disabled}
        </span>
      </li>
      {group.inventories_overdued > 0 && (
        <li className="flex items-center justify-between gap-2">
          <span className="overflow line-clamp-1">{t("groups.table.availability.overdue")}</span>
          <span className="text-nowrap btn btn-primary-8 btn-color-primary py-1 px-2 text-sm font-medium btn-icon">
            {group.inventories_overdued}
          </span>
        </li>
      )}
      {group.inventories_deleted > 0 && (
        <li className="flex items-center justify-between gap-2">
          <span className="overflow line-clamp-1">Архив</span>
          <span className="text-nowrap btn btn-red-8 btn-color-red py-1 px-2 text-sm font-medium btn-icon">
            {group.inventories_deleted}
          </span>
        </li>
      )}

      {states.map((state) => (
        <InventoryGroupStateComponent key={`${state.id}_${state.group}`} stateId={state.id} count={state.count} />
      ))}

      <li className="flex items-center justify-between gap-2">
        <span className="overflow line-clamp-1">Активно</span>
        <span className="text-nowrap btn py-1 px-2 text-sm font-medium btn-icon">{group.inventories_count}</span>
      </li>
    </div>
  ) : (
    <div className="btn p-1 btn-icon text-start text-nowrap">
      {t("groups.table.availability.available", {
        count:
          group.inventories_count -
          Math.max(group.inventories_occupied, group.inventories_overdued) -
          group.inventories_disabled
      })}
    </div>
  )
}

export default function SharedInventoryGroupListComponent(config: {
  params: any
  onSelect?: (group: InventoryGroupModel, count?: number, filter?: any) => Promise<number[]>
  action?: (group: InventoryGroupModel) => JSX.Element
  addDisabled?: boolean
  title?: JSX.Element
}) {
  const {settings, constantsMap, isMobile, pointId, tenant} = useContext(ConfigContext)
  const {searchParams, setSearchParams} = useQueryParams()
  const archived = searchParams.archived ? searchParams.archived.toLowerCase() === "true" : false
  const [list, setList] = useState<ListModel<InventoryGroupModel>>(EMPTY_LIST)
  const [groupState, setGroupState] = useState<Record<number, InventoryGroupStateModel[]>>({})
  const accessExceptTransfer = tenant && tenant.type_code === TenantType.TRANSFER
  const form = useForm<InventoryGroupFilterForm>({
    defaultValues: {
      page: 1,
      pageSize: 10,
      rental_point: pointId,
      search: "",
      type: 0,
      ...searchParams,
      ...config.params,
      archived
    }
  })
  const {reset, setValue, watch} = form
  const [general, setGeneral] = useState<InventoryGeneralModel>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const navigate = useNavigate()
  const values = watch()
  const {t} = useTranslation()

  const isSell = +watch("type") === 1
  const isArchived = Boolean(watch("archived"))

  const onSingleInventoryGroupArchiveModal = (group: InventoryGroupModel) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const onConfirm = () =>
      inventoryGroupService
        .archive(group.id)
        .then(() => listGroups(values))
        .then(() => setSelectedRowKeys([]))

    modalService.open({
      component: (
        <ConfirmModal
          delete={!group.deleted}
          confirm_text={t(group.deleted ? "common.archive.return" : "common.archive.archive")}
          message={t(group.deleted ? "common.archive.to_return.group" : "common.archive.to_archive")}
          onConfirm={onConfirm}
        />
      )
    })
  }

  const onMultiInventoryGroupArchiveModal = async () => {
    const onConfirm = () =>
      Promise.all(selectedRowKeys.map((groupId) => inventoryGroupService.archive(groupId)))
        .then(() => listGroups(values))
        .then(() => setSelectedRowKeys([]))
    modalService.open({
      component: (
        <ConfirmModal
          delete={!isArchived}
          confirm_text={t(isArchived ? "common.archive.return" : "common.archive.archive")}
          message={t(isArchived ? "common.archive.to_return.group" : "common.archive.to_archive")}
          onConfirm={onConfirm}
        />
      )
    })
  }

  const mobileColumns = settings
    ? [
        {
          width: "100%",
          title: t("groups.table.name"),
          dataIndex: "name",
          key: "name",
          render: (name, group) => {
            const freeCount = group.inventories_count - group.inventories_overdued - group.inventories_disabled
            return (
              <div className="flex gap-2 items-center">
                <ImageField src={group.image} style={{width: 48, height: 48, borderRadius: 8}} />
                <div className={"flex flex-col gap-2"}>
                  <span className="font-medium">{name}</span>
                  <div
                    className={`font-medium w-fit min-h-[27px] rounded-md flex justify-center items-center px-2 py-[6px] ${freeCount !== 0 ? "bg-accept bg-opacity-20 text-accept" : "bg-[#F5222D] bg-opacity-20 text-[#F5222D]"}`}>
                    {freeCount !== 0 ? freeCount : freeCount + "/" + group.inventories_count} свободно
                  </div>
                </div>
              </div>
            )
          }
        },
        {
          width: 240,
          title: "",
          key: "group_action",
          hidden: isNil(config.onSelect),
          render: (_, group) => (
            <InventoryGroupAddCell
              group={group}
              _default={config.addDisabled ? config.addDisabled : true}
              onSelect={(group, count) =>
                config.onSelect(group, count, watch()).then((ids) => {
                  const excludeIds = values?.exclude_ids || ""
                  reset({
                    ...values,
                    exclude_ids: [...(excludeIds !== "" ? excludeIds.split(",") : []), ...ids].join(",")
                  })
                })
              }
            />
          )
        }
      ]
    : []

  const columns: ColumnsType<InventoryGroupModel> = settings
    ? [
        {
          width: "100%",
          title: t("groups.table.name"),
          dataIndex: "name",
          key: "name",
          render: (name, group) => (
            <div className="flex gap-2 items-center">
              <ImageField src={group.image} style={{width: 48, height: 48, borderRadius: 8}} />
              <span className="font-medium">{name}</span>
            </div>
          ),
          sorter: true
        },
        {
          width: 96,
          title: t("groups.table.unique_id"),
          dataIndex: "unique_id",
          key: "unique_id",
          render: (unique_id) => unique_id,
          sorter: true
        },
        {
          width: 144,
          title: t("groups.table.category"),
          dataIndex: "category",
          key: "category",
          render: (category) => constantsMap.INVENTORY_CATEGORIES[category]?.name,
          sorter: true
        },
        {
          width: 160,
          title: t("groups.table.created_at"),
          dataIndex: "created_at",
          key: "created_at",
          render: (created_at) => toDateTime(created_at),
          sorter: true
        },
        {
          width: 160,
          title: t("groups.table.availability.column"),
          dataIndex: "inventories_count",
          key: "inventories_count",
          render: (_, group) => <InventoryGroupAvailability group={group} states={groupState[group.id] || []} />,
          sorter: true
        },
        ...settings.custom_fields.inventory_group
          .filter((field) => field.table)
          .map((field) => ({
            width: 96,
            title: field.label,
            key: field.name,
            render: (_, group) =>
              group && group.extra && field.type !== "boolean"
                ? group.extra[field.name]
                : t(Boolean(group.extra[field.name]) ? "common.boolean.true" : "common.boolean.false")
          })),
        {
          width: 80,
          title: "",
          key: "archive",
          hidden: !isNil(config.onSelect),
          onHeaderCell: () => ({style: {width: 80}}),
          onCell: () => ({style: {width: 80}}),
          render: (_, group) => (
            <button onClick={onSingleInventoryGroupArchiveModal(group)} className="btn btn-card">
              <Icon icon={group.deleted ? "restore_from_trash" : "archive"} />
            </button>
          )
        },
        {
          width: 160,
          title: "",
          key: "action",
          hidden: !config.action,
          render: (_, group) => config.action(group)
        },
        {
          width: 240,
          title: "",
          key: "group_action",
          hidden: isNil(config.onSelect),
          render: (_, group) => (
            <InventoryGroupAddCell
              group={group}
              _default={config.addDisabled ? config.addDisabled : true}
              onSelect={(group, count) =>
                config.onSelect(group, count, watch()).then((ids) => {
                  const excludeIds = values?.exclude_ids || ""
                  reset({
                    ...values,
                    exclude_ids: [...(excludeIds !== "" ? excludeIds.split(",") : []), ...ids].join(",")
                  })
                })
              }
            />
          )
        }
      ]
    : []

  const rowSelection: TableRowSelection<InventoryGroupModel> = {
    selectedRowKeys,
    onChange: (keys: number[]) => setSelectedRowKeys(keys),
    columnWidth: 48,
    columnTitle: (
      <ControlCheckbox
        className="form-check-input"
        checked={selectedRowKeys.length !== 0 && selectedRowKeys.length === list.results.length}
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length !== list.results.length}
        onChange={() => setSelectedRowKeys((prev) => (prev.length === 0 ? list.results.map((obj) => obj.id) : []))}
      />
    ),
    renderCell: (val: boolean, inventory: InventoryGroupModel) => (
      <ControlCheckbox
        className="form-check-input"
        checked={val}
        onClick={(e) => e.stopPropagation()}
        onChange={() =>
          setSelectedRowKeys((prev) =>
            prev.includes(inventory.id) ? prev.filter((k) => k !== inventory.id) : [...prev, inventory.id]
          )
        }
      />
    )
  }

  const listGroups = async (params: any) => {
    const [general, list] = await Promise.all([inventoriesService.general(params), inventoryGroupService.list(params)])
    const groupStates = await inventoryGroupService.listStates({
      group_ids: list.results.map((obj) => obj.id)
    })
    setGroupState(
      groupStates.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.group]: curr.group in prev ? [...prev[curr.group], curr] : [curr]
        }),
        {}
      )
    )
    setGeneral(general)
    setList(list)
  }

  useEffect(() => {
    listGroups(values)
    const sub = watch((params, {name}) => {
      if (name !== "search") listGroups(params).catch((e) => e.response.status === 404 && setValue("page", 1))
      const query = objectFilter(params)
      isNil(config.onSelect) && setSearchParams(query)
    })

    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  const cardSectionData: CardSection[] = [
    {
      title: t("groups.general.label_all"),
      values: isSell
        ? [
            {
              label: t("groups.general.group_count"),
              value: list.count
            },
            {
              label: t("groups.general.inventory_count"),
              value: general && general.company.count + general.sublease.count
            }
          ]
        : [
            {
              label: t("groups.general.group_count"),
              value: list.count
            },
            {
              label: t("groups.general.inventory_count"),
              value: general && general.company.count + general.sublease.count
            },
            {
              label: t("groups.general.company_inventory_count"),
              value: general && general.company.count
            },
            {
              label: t("groups.general.sublease_inventory_count"),
              value: general && general.sublease.count
            }
          ]
    },
    {
      title: t("groups.general.label_inrent"),
      values: [
        {
          label: t("groups.general.inrent_count"),
          value: general && general.company.count_inrent + general.sublease.count_inrent
        },
        {
          label: t("groups.general.company_inventory_count"),
          value: general && general.company.count_inrent
        },
        {
          label: t("groups.general.sublease_inventory_count"),
          value: general && general.sublease.count_inrent
        }
      ]
    }
  ]

  return (
    <FormProvider {...form}>
      <InventoryGroupListFilterComponent />

      {isNil(config.onSelect) && (
        <CardWrapper cardSectionData={isSell ? cardSectionData.splice(0, 1) : cardSectionData} />
      )}

      <Table
        showHeader={!isMobile}
        className={isMobile && "!rounded-0 !border-0"}
        title={
          isNil(config.onSelect) &&
          (() => (
            <div className={`${accessExceptTransfer && "hidden"} flex gap-2 items-start justify-between w-full`}>
              <div>
                {config.title || <div />}
                <Segmented
                  value={isArchived}
                  className="flex w-full md:w-fit bg-gray-100 rounded-md"
                  onChange={() => {
                    setValue("archived", !isArchived)
                    setSelectedRowKeys([])
                  }}
                  style={{
                    background: "var(--color-gray-70)",
                    padding: 4,
                    borderRadius: 6
                  }}
                  options={[
                    {
                      label: "Все продукты",
                      value: false,
                      className: "md:p-1 font-medium text-black md:h-auto text-center"
                    },
                    {
                      label: "Архив",
                      value: true,
                      className: "md:p-1 font-medium text-black md:h-auto text-center"
                    }
                  ]}
                  {...(isMobile && {block: true})}
                />
              </div>

              {selectedRowKeys.length > 0 ? (
                <>
                  <button className="btn btn-card gap-2" onClick={onMultiInventoryGroupArchiveModal}>
                    <Icon icon="archive" />
                    <span className="font-medium text-[13px]">
                      {t(isArchived ? "common.archive.return" : "common.archive.archive")}
                    </span>
                  </button>
                  {/*<div className="vr text-gray-200 " style={{margin: "12px 0"}}></div>*/}
                </>
              ) : (
                <></>
              )}
              {/*<button*/}
              {/*  className={`btn btn-card ${isArchived ? "light" : ""} gap-2`}*/}
              {/*  onClick={() => {*/}
              {/*    setValue("archived", !isArchived)*/}
              {/*    setSelectedRowKeys([])*/}
              {/*  }}>*/}
              {/*  <Icon icon="package_2" />*/}
              {/*  {t("common.archive.label")}*/}
              {/*</button>*/}
            </div>
          ))
        }
        rowKey={(obj) => obj.id}
        rowSelection={isNil(config.onSelect) ? rowSelection : undefined}
        columns={isMobile ? mobileColumns : columns}
        onRow={(group) => ({
          onClick: () => {
            if (selectedRowKeys.length > 0) {
              setSelectedRowKeys((prev) =>
                prev.includes(group.id) ? prev.filter((k) => k !== group.id) : [...prev, group.id]
              )
              return
            }
          }
        })}
        onNavigate={isNil(config.onSelect) ? (group) => navigate(`/groups/${group.id}`) : undefined}
        dataSource={list.results}
      />

      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}
