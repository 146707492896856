import {useTranslation} from "react-i18next"
import {useContext, useEffect, useState} from "react"
import {Segmented} from "src/components/shared/antd-custom"
import subscriptionModalService, {SubscriptionModalType} from "src/services/subscription.service"
import tenantService, {Subscription, SubscriptionPeriod, TenantModel} from "src/services/tenant.service"
import Icon from "src/components/shared/components/material-icon"
import moment from "moment"
import {ConfigContext} from "src/app"
import clsx from "clsx"
import {priceBeautify, toPrice} from "src/utils/price"
import {TariffPermissionList} from "../../../pages/profile/my-tariff/tariff-permission-list"

type CURRENCY = "USD" | "KZT"

function Permission({permitted, children}: {permitted: boolean; children: JSX.Element | string}) {
  return (
    <div className="flex gap-2 items-center text-[15px]">
      {permitted ? <Icon icon="check" className="text-accept" /> : <Icon icon="close" className="text-alert" />}
      {children}
    </div>
  )
}

const currencyFormatter: Record<CURRENCY, string> = {USD: "$", KZT: "₸"}
const defaultMonth = 1

function TenantSubscriptionTarif({
  subscription,
  currency,
  option,
  setOption
}: {
  subscription: Subscription
  currency: CURRENCY
  option
  setOption
}) {
  const {tenant} = useContext(ConfigContext)
  const {t} = useTranslation()

  const options = subscription.periods
    .filter((period) => period.currency === currency)
    .map((period) => {
      const label = moment.duration(period.months, "months").add(period.days, "days").humanize()
      return {label, className: "w-full py-1", value: period.months}
    })

  const tarifMap: Record<number, SubscriptionPeriod> = Object.fromEntries(
    subscription.periods.filter((period) => period.currency === currency).map((period) => [period.months, period])
  )

  if (!tarifMap[option] || !tarifMap[defaultMonth]) return <></>

  const onSubscriptionPayment = async () => {
    const ret = await tenantService.createInvoice({
      subscription: subscription.id,
      period: tarifMap[option].id,
      success_url: window.location.href,
      extra: {}
    })
    if (ret.invoice && ret.invoice.extra && +ret.invoice.status !== 3 && "wooppay_link" in ret.invoice.extra) {
      window.location.replace(ret.invoice.extra.wooppay_link)
    } else {
      window.location.reload()
    }
  }

  const initialPrice = priceBeautify(tarifMap[defaultMonth].price / tarifMap[defaultMonth].months, 0)
  const currentPrice = priceBeautify(tarifMap[option].price / tarifMap[option].months, 0)

  return (
    <div
      className={clsx(
        "min-w-64 flex flex-col flex-1 gap-6 px-6 py-5 shadow rounded-lg",
        subscription.name === "Pro" ? "border-[1.5px] border-primary" : "border border-gray-100"
      )}>
      <div className="flex flex-col gap-[6px]">
        <div className="text-2xl text-black font-bold flex gap-2 items-center">
          {subscription.name}
          {subscription.name === "Pro" && (
            <span className="text-primary text-xs font-medium bg-primary bg-opacity-20 rounded-md py-1 px-2">
              Популярно
            </span>
          )}
        </div>

        {subscription.name !== "Freemium" && subscription.name !== "Enterprise" && (
          <div className="text-sm font-medium text-black gap-2">
            {option !== 1 && (
              <span className="text-alert line-through">
                {currencyFormatter[currency]}
                {initialPrice}
              </span>
            )}
            &nbsp;
            <span className="text-[14px]">
              {currencyFormatter[currency]}
              {currentPrice}
            </span>
            <span className="font-medium text-sm"> / {t("profile.subscription.tarif.monthly")}</span>
          </div>
        )}

        {tenant && tenant.subscription && (
          <span className={"text-gray-350 font-normal text-[13px]"}>
            Действительно до {moment(tenant.end_at).format("DD.MM.YYYY")}
          </span>
        )}
      </div>

      {subscription.name !== "Freemium" && subscription.name !== "Enterprise" && (
        <Segmented<number>
          value={option}
          onChange={setOption}
          style={{
            background: "var(--color-gray-70)",
            padding: 4,
            borderRadius: 6
          }}
          options={options}
        />
      )}

      {subscription.name === "Enterprise" && <button className="btn btn-black">Связаться с нами</button>}

      {subscription.name !== "Enterprise" && (
        <button className="btn btn-black" onClick={onSubscriptionPayment}>
          {t("profile.subscription.proceed_to_payment")}
        </button>
      )}

      <div className="flex flex-col gap-2 flex-1">
        <TariffPermissionList {...subscription} />
      </div>
    </div>
  )
}

export function TenantSubscriptionModal() {
  const [option, setOption] = useState<number>(6)
  const [subs, setSubs] = useState<Subscription[]>([])
  const [currency, _] = useState<CURRENCY>("KZT")
  const {t} = useTranslation()
  const {tenant} = useContext(ConfigContext)

  useEffect(() => {
    const sub = tenantService.subscriptions$.subscribe(setSubs)
    return () => sub.unsubscribe()
  }, [])

  return (
    option && (
      <div className="flex flex-col gap-0 w-full">
        <div className="py-8 border-b border-gray-100">
          <div className="px-8">
            <h3 className="text-3xl font-bold mb-3">{t("profile.subscription.title")}</h3>
            {tenant.subscription && (
              <div className="text-[15px]">
                {t("profile.subscription.free", {
                  tariffName: tenant.subscription.name,
                  tariffPrice: `${priceBeautify(tenant.period.price, 0)} ${tenant.period.currency}`,
                  tariffPeriod: [
                    tenant.period.months && `${tenant.period.months} ${t("common.times_abb.month")}`,
                    tenant.period.days && tenant.period.days > 0 && `${tenant.period.days} ${t("common.times_abb.day")}`
                  ]
                    .filter(Boolean)
                    .join(", ")
                })}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row overflow-x-scroll gap-3 p-3 md:gap-6 md:p-8">
          {subs.map((sub) => (
            <TenantSubscriptionTarif
              key={sub.id}
              subscription={sub}
              option={option}
              setOption={setOption}
              currency={currency}
            />
          ))}
        </div>
      </div>
    )
  )
}
