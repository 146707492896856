import React, {useContext, useMemo, useState} from "react"
import {useFormContext} from "react-hook-form"
import dayjs, {Dayjs} from "dayjs"
import {DatePicker, Modal} from "antd"
import CustomSelect from "src/components/shared/inputs/select/select"
import {useTranslation} from "react-i18next"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"

const {RangePicker} = DatePicker

function getDateRangeByValue(value: string): [Dayjs, Dayjs] | null {
  switch (value) {
    case "today": {
      return [dayjs().startOf("day"), dayjs().endOf("day")]
    }
    case "tomorrow": {
      const tom = dayjs().add(1, "day").startOf("day")
      return [tom, tom.endOf("day")]
    }
    case "after_tomorrow": {
      const aft = dayjs().add(2, "day").startOf("day")
      return [aft, aft.endOf("day")]
    }
    case "this_week": {
      return [dayjs().startOf("week"), dayjs().endOf("week")]
    }
    case "two_weeks": {
      return [dayjs().startOf("day"), dayjs().add(2, "week").endOf("day")]
    }
    case "month": {
      return [dayjs().startOf("month"), dayjs().endOf("month")]
    }
    default:
      return null
  }
}

interface ICustomDateSelectProps {
  name_start: string
  name_end: string
  className?: string
  allowClear?: boolean
}

const MultimodeDatePicker: React.FC<ICustomDateSelectProps> = ({
  name_start,
  name_end,
  className,
  allowClear = false
}) => {
  const {setValue, watch, reset} = useFormContext()
  const values = watch()
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  const [selectedOption, setSelectedOption] = useState<{
    label: string
    value: string
  } | null>(null)
  const isCustomSelected = selectedOption?.value === "custom"
  const [showModal, setShowModal] = useState(false)
  const startVal = watch(name_start)
  const endVal = watch(name_end)
  const startDate = startVal ? dayjs(startVal) : null
  const endDate = endVal ? dayjs(endVal) : null

  const dateOptions = useMemo(
    () => [
      {label: t("orders.list.range_presets.today"), value: "today"},
      {label: t("orders.list.range_presets.tomorrow"), value: "tomorrow"},
      {label: t("orders.list.range_presets.after_tomorrow"), value: "after_tomorrow"},
      {label: t("orders.list.range_presets.this_week"), value: "this_week"},
      {label: t("orders.list.range_presets.next_two_weeks"), value: "two_weeks"},
      {label: t("orders.list.range_presets.one_month"), value: "month"},
      {label: t("orders.list.range_presets.choose_date"), value: "custom"}
    ],
    [t]
  )

  const handleSelectChange = (option: {label: string; value: string} | null) => {
    setSelectedOption(option)
    if (!option) {
      reset({
        ...values,
        [name_start]: "",
        [name_end]: ""
      })
      return
    }

    if (option.value === "custom") {
      if (isMobile) {
        setShowModal(true)
      }
    } else {
      const quickRange = getDateRangeByValue(option.value)
      if (quickRange) {
        const [start, end] = quickRange
        reset({
          ...values,
          [name_start]: start.format("YYYY-MM-DD"),
          [name_end]: end.format("YYYY-MM-DD")
        })
      }
    }
  }

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (!dates || !dates[0] || !dates[1]) {
      return
    }
    reset({
      ...values,
      [name_start]: dates[0].format("YYYY-MM-DD"),
      [name_end]: dates[1].format("YYYY-MM-DD")
    })
    setShowModal(false)
  }

  const onSingleChange = (date: Dayjs | null, fieldName: string) => {
    if (date) {
      setValue(fieldName, date.format("YYYY-MM-DD"))
    } else {
      setValue(fieldName, "")
    }
  }

  return (
    <div className={className}>
      <div className={`${isMobile ? "w-full" : "flex gap-4"}`}>
        <CustomSelect
          options={dateOptions}
          value={selectedOption}
          onChange={handleSelectChange}
          placeholder={t("orders.list.range_presets.choose_date")}
          isClearable
        />

        {!isMobile && isCustomSelected && (
          <RangePicker
            onChange={handleRangeChange}
            format="DD.MM.YYYY"
            className="form-control"
            value={startDate && endDate ? [startDate, endDate] : null}
            allowClear={allowClear}
          />
        )}
      </div>

      <Modal
        title={t("orders.list.range_presets.choose_date")}
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}>
        <div className={`flex flex-1 gap-1 items-center ${className || ""}`}>
          <DatePicker
            className="form-control"
            style={{width: "100%"}}
            format="DD-MM-YYYY"
            minuteStep={5}
            allowClear={allowClear}
            onChange={(date) => onSingleChange(date, name_start)}
            value={startDate}
          />
          <Icon icon="east" className="text-xl" />
          <DatePicker
            className="form-control"
            style={{width: "100%"}}
            format="DD-MM-YYYY"
            minuteStep={5}
            allowClear={allowClear}
            onChange={(date) => onSingleChange(date, name_end)}
            value={endDate}
          />
        </div>
      </Modal>
    </div>
  )
}

export default MultimodeDatePicker
