import moment from "moment"
import {InputHTMLAttributes, useContext} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import {isNil} from "src/utils/isNil"
import {DatePicker, RangePicker} from "../antd-custom"
import {ConfigContext} from "src/app"
import Icon from "../components/material-icon"

interface FormControlConfig extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  rootclassname?: string
  name_start: string
  name_end: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  allowClear?: boolean
  onChange?: (value: [moment.Moment, moment.Moment] | null) => void
}

export function FormControlDaterange(config: FormControlConfig) {
  const {watch, reset, setValue} = useFormContext()
  const {isMobile} = useContext(ConfigContext)
  const values = watch()
  const start = values[config.name_start] ? moment(values[config.name_start]) : undefined
  const end = values[config.name_end] ? moment(values[config.name_end]) : undefined

  const onChange = (days: [moment.Moment, moment.Moment] | null) => {
    if (isNil(days)) {
      reset({
        ...values,
        [config.name_start]: null,
        [config.name_end]: null
      })
      return
    }
    const start = days[0]
    const end = days[1]
    reset({
      ...values,
      [config.name_start]: start.format("YYYY-MM-DD"),
      [config.name_end]: end.format("YYYY-MM-DD")
    })
    if (config.onChange) config.onChange(days)
  }

  const onSingleChange = (date: moment.Moment | null, name: string) => {
    if (isNil(date)) {
      setValue(name, null, {shouldDirty: true, shouldTouch: true, shouldValidate: true})
      return
    }
    setValue(name, date.format("YYYY-MM-DD"), {shouldDirty: true, shouldTouch: true, shouldValidate: true})
  }

  return (
    <div className={config.rootclassname || ""}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] text-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      {isMobile ? (
        <div className="flex flex-1 gap-1 items-center">
          <DatePicker
            className="form-control"
            style={{width: "100%"}}
            format="DD-MM-YYYY"
            minuteStep={5}
            onChange={(date) => onSingleChange(date, config.name_start)}
            value={start}
            name={config.name_start}
            allowClear={config.allowClear || false}
          />
          <Icon icon="east" className="text-xl" />
          <DatePicker
            className="form-control"
            style={{width: "100%"}}
            format="DD-MM-YYYY"
            minuteStep={5}
            onChange={(date) => onSingleChange(date, config.name_end)}
            value={end}
            name={config.name_end}
            allowClear={config.allowClear || false}
          />
        </div>
      ) : (
        <RangePicker
          className={config.className}
          style={config.style}
          allowClear={config.allowClear || false}
          value={[start, end]}
          onChange={onChange}
        />
      )}
    </div>
  )
}
