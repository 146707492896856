import React from "react"
import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import {SharedInventoryStateSelect} from "src/components/shared/components/select/inventory-state"
import {BaseMaintenanceModel, InventoryMaintenanceModel} from "src/models/manager/maintenance/maintenance.model"
import modalService from "src/components/modal/global/modal.service"
import {FormControlTextarea} from "src/components/shared/inputs/form-control-textarea"

interface FinishMaintenanceForm {
  state: number
  comment: string
}
const MaintenanceFinishModal = (config: {
  inventory: InventoryMaintenanceModel
  maintenance: BaseMaintenanceModel
  onSubmit: (payload: FinishMaintenanceForm) => Promise<any>
}) => {
  const {t} = useTranslation()
  const {inventory} = config
  const form = useForm<FinishMaintenanceForm>()
  const onSubmit = form.handleSubmit((payload) => {
    config.onSubmit(payload).then(() => modalService.closeAllModal())
  })

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-8">
        <div className="font-semibold text-2xl text-black">
          {"FINISH"} - {inventory.name} ({inventory.unique_id})
        </div>
        <SharedInventoryStateSelect
          name="state"
          placeholder={t("maintenance.condition")}
          isClearable={true}
          menuPortalTarget={document.body}
          label={t("maintenance.condition")}
          className="flex-1"
          menuPlacement="bottom"
          isSearchable={false}
        />
        <FormControlTextarea
          name="comment"
          label={t("common.input.textarea_label")}
          rootclassname="w-full"
          className="form-control cursor-text h-[187px] placeholder:text-gray-350"
          placeholder={t("groups.inventories.form.comment_placeholder")}
          required={false}
        />
        <div className="flex justify-end gap-4">
          <button className={"btn btn-card font-medium bg-gray-70 w-[105px]"} onClick={() => modalService.closeModal()}>
            {t("common.back")}
          </button>
          <button className={"btn btn-primary btn-color-white font-medium w-[135px]"} onClick={onSubmit}>
            {t("common.save")}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}

export default MaintenanceFinishModal
