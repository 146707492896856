import {useContext, useEffect, useState} from "react"
import {toPrice} from "src/utils/price"
import {EMPTY_LIST, ListModel, ListParams} from "src/models/common"
import servicesService from "src/services/services"
import {ServiceModel} from "src/models/manager/order/order-request-service-new"
import Table from "src/components/shared/antd-custom/table"
import type {ColumnsType} from "antd/es/table"
import PaginationComponent from "src/components/shared/pagination/pagination"
import toDateTime from "src/utils/date-time"
import {useTranslation} from "react-i18next"
import modalService from "../../../../components/modal/global/modal.service"
import {OrderContext} from "../context/order-context"
import {colorPaletes} from "../../../../components/shared/constants"
import {ConfigContext} from "src/app"

export default function OrderServicesAddModal() {
  const paramsState = useState<ListParams>({page: 1, pageSize: 10})
  const {t} = useTranslation()
  const [params] = paramsState
  const [list, setList] = useState<ListModel<ServiceModel>>(EMPTY_LIST)
  const {createServicesForm, orderForm} = useContext(OrderContext) || {}
  const {isMobile} = useContext(ConfigContext)

  useEffect(() => {
    servicesService.list(params).then(setList)
  }, [params])

  const mobileColumns: ColumnsType<ServiceModel> = [
    {
      width: "auto",
      title: t("groups.table.name"),
      dataIndex: "name",
      key: "name",
      render: (_, set) => {
        const {id, name, tarifs, created_at} = set

        return (
          <div className="flex flex-col gap-2">
            <p className="font-medium text-[15px] text-black">{name}</p>
            <div className={"flex gap-1"}>
              <p className="font-normal text-[13px] text-gray-370">№{id}</p>
              {tarifs.length !== 0 ? (
                <>
                  <p className="font-normal text-[13px] text-gray-370">•</p>
                  <p className="font-normal text-[13px] text-gray-370">
                    {tarifs.map((tarif) => toPrice(tarif.price, "", ""))}
                  </p>
                </>
              ) : (
                <>
                  <p className="font-normal text-[13px] text-gray-370">•</p>
                  <p className="font-normal text-[13px] text-gray-370">Нет тарифов</p>
                </>
              )}
            </div>
          </div>
        )
      }
    }
  ]

  const columns: ColumnsType<ServiceModel> = [
    {
      width: 48,
      title: "№",
      key: "id",
      dataIndex: "id",
      render: (id) => id
    },
    {
      width: 240,
      title: t("orders.service.table.name"),
      key: "name",
      dataIndex: "name",
      render: (name) => name
    },
    {
      width: 180,
      title: t("orders.service.table.tarifs"),
      key: "tarifs",
      dataIndex: "tarifs",
      render: (tarifs) => (
        <div className="flex gap-1 flex-wrap">
          {tarifs.map((tarif) => toPrice(tarif.price, "", tarifs.length <= 1 ? "" : ", "))}
        </div>
      )
    },
    {
      width: 160,
      title: t("orders.service.table.created_at"),
      key: "created_at",
      dataIndex: "created_at",
      render: (created_at) => toDateTime(created_at)
    }
  ]

  return (
    <>
      <PaginationComponent params={paramsState} count={list.count} />
      <Table
        showHeader={!isMobile}
        className={isMobile && "!rounded-0 !border-0"}
        rowKey={(obj) => obj.id}
        onRow={(service) => ({
          onClick: () => {
            createServicesForm.append({
              object_id: null,
              service,
              price: 0,
              price_discount: 0,
              start_at: orderForm.watch("rent_start"),
              end_at: orderForm.watch("rent_end")
            })

            modalService.closeModal()
          }
        })}
        rootClassName="w-full"
        dataSource={list.results}
        columns={isMobile ? mobileColumns : columns}
        autoScroll={true}
      />
    </>
  )
}
