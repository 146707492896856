import {RentalPointModel} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import {useContext, useMemo} from "react"
import {ConfigContext} from "src/app"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import {useFormContext} from "react-hook-form"

type RemovalKeys = "options" | "value" | "getOptionLabel" | "getOptionValue"

export function SharedRentalPointSelect(config: Omit<FormSelectConfig<RentalPointModel>, RemovalKeys>) {
  const {t} = useTranslation()
  const form = useFormContext()
  const {profile, constants, constantsMap} = useContext(ConfigContext)

  const value = useMemo(() => {
    const val = form.watch(config.name)
    if (Array.isArray(val)) return val.map((v) => constantsMap.RENTAL_POINTS[+v])
    return constantsMap.RENTAL_POINTS[+val]
  }, [form, form.watch(config.name)])

  if (!profile) return

  return (
    <FormSelect<RentalPointModel>
      {...config}
      options={constants.RENTAL_POINTS.filter((point) => profile.is_superuser || profile.points.includes(point.id))}
      value={value}
      placeholder={config.placeholder || t("common.select.rental_point")}
      getOptionLabel={(option: RentalPointModel) => (option ? String(option.name) : null)}
      getOptionValue={(option: RentalPointModel) => (option ? String(option.id) : null)}
    />
  )
}
