import React, {useContext, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {LabelModel} from "src/models/label"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import {FormControl} from "../../inputs/form-control"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import transportationService from "../../../../services/transportation.service"
import {FormControlMask} from "../../inputs/form-control-mask"
import {useFormContext} from "react-hook-form"
import {ConfigContext} from "src/app"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options" | "modalProps"

interface TransferAgentConfig extends Omit<FormSelectConfig<LabelModel>, RemovalKeys> {
  addOption?: boolean
}

const TransferAgent: React.FC<TransferAgentConfig> = (config) => {
  const {t} = useTranslation()
  const form = useFormContext()
  const {settings} = useContext(ConfigContext)
  const [agents, setAgents] = useState<any>([])
  const value = agents.find((a) => +a.id === +form.watch(config.name))

  const onCreate = async (payload: Partial<LabelModel>) => {
    try {
      await transportationService.createReferral(payload)
      modalService.closeModal()
    } catch (error) {
      console.error("Error creating address:", error)
    }
  }

  const modalProps: ExtendedModalConfig = {
    text: t("transfers.action.new_agent"),
    props: {size: "lg", backdrop: true},
    component: (
      <ConfigAddOrEdit<LabelModel>
        onSubmit={(city) => onCreate(city).then(() => modalService.closeModal())}
        buttonTitle={t("common.add")}
        title={t("transfers.action.add_customer")}
        mode="add">
        <FormControl
          name="name"
          label={t("common.input.name")}
          rootclassname="col"
          className="form-control"
          params={{required: true}}
          required
          placeholder={t("transfers.action.enter_the_customer")}
        />
        <FormControlMask
          label={t("clients.form.phone")}
          placeholder={t("common.input.placeholder.phone_number")}
          rootclassname="col"
          name="phone"
          mask={settings && settings.phone_format}
          className="form-control"
        />
      </ConfigAddOrEdit>
    )
  }

  useEffect(() => {
    const sub = transportationService.agents$.subscribe(setAgents)
    return () => sub.unsubscribe()
  }, [])

  return (
    <FormSelect<any>
      {...config}
      options={agents}
      value={value}
      modalProps={config.addOption && modalProps}
      getOptionLabel={(option: any) => (option ? String(option.name) : null)}
      getOptionValue={(option: any) => (option ? String(option.id) : null)}
    />
  )
}

export default TransferAgent
