import {useEffect, useState} from "react"
import {FormProvider, RegisterOptions, useFieldArray, useForm} from "react-hook-form"
import {CustomFieldSetFilterList} from "src/pages/settings/custom-field/field-set-filter"
import {FormControlEntity} from "src/components/shared/inputs/form-control-entity"
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model"
import {CustomFieldOption} from "src/models/setting-config"
import settingService from "src/services/settings.service"
import {map} from "rxjs"
import "./styles.scss"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"

export interface Params {
  name: string
  required?: boolean
  params?: RegisterOptions<any, any>
}

export const OrderDetailSetFilterComponent = ({
  set,
  count = 1,
  onSubmit
}: {
  set: InventorySetModel
  count: number
  onSubmit: (payload: Record<string, any>) => void
}) => {
  const {t} = useTranslation()

  const form = useForm<{
    list: Array<{count: number; filter: Record<string, any>}>
  }>({
    defaultValues: {list: [{count, filter: {}}]}
  })

  const filters = useFieldArray({control: form.control, name: "list"})

  const onAdd = () => filters.append({count: 1, filter: []})
  const onDelete = (index: number) => filters.remove(index)

  const [customFields, setCustomFields] = useState<string[]>([])
  const overallCount = form.watch("list").reduce((p, form) => p + +form.count, 0)

  useEffect(() => {
    const sub = settingService.config$
      .pipe(map((config) => config.custom_fields.inventory.filter((field) => field.filter)))
      .subscribe((fields) => setCustomFields(fields.map((field) => field.name)))
    return () => sub.unsubscribe()
  }, [])

  const items = set.items.filter((item) => {
    console.log(item)
    return true
  })

  return (
    <FormProvider {...form}>
      <div className="flex gap-2 justify-between items-end mb-3">
        <h4 className="font-semibold text-xl mb-0">{set.name}</h4>
        <div className="text-gray-500 text-sm">
          {t("orders.inventory.modals.filter_modal.total_amount")}: {overallCount}
        </div>
      </div>

      {filters.fields.map((filter, index) => (
        <div key={filter.id}>
          <div className="text-gray-500 text-sm mb-2">
            {t("orders.inventory.modals.filter_modal.set")} {index + 1}
          </div>
          <div className="card flex gap-2 mb-3">
            <div className="set-filter-grid">
              {items.map((field) => (
                <div>
                  <label className="overflow line-clamp-1 text-[13px] text-gray-400 mb-1">{field.group_name}</label>
                  <CustomFieldSetFilterList
                    key={`${field.id}_${index}`}
                    filter={{group_id: field.group_id}}
                    prefix={`list.${index}.filter.${field.id}.extra__`}
                  />
                </div>
              ))}
            </div>
            <FormControlEntity
              name={`list.${index}.count`}
              maxcount={100}
              rootclassname="flex justify-end align-self-end"
            />
            <button
              onClick={() => onDelete(index)}
              className="absolute -right-2 -top-2 rounded-full bg-alert w-6 h-6 flex items-center justify-center">
              <Icon icon="add" className="text-white leading-5 text-[20px] rotate-45" />
            </button>
          </div>
        </div>
      ))}

      <button className="btn btn-card w-full mb-3 gap-1" onClick={onAdd}>
        <Icon icon="add" />
        {t("common.add")}
      </button>

      <div className="flex justify-end">
        <button
          className="btn btn-primary btn-color-white"
          disabled={filters.fields.length === 0}
          onClick={form.handleSubmit(onSubmit)}>
          {t("common.add")}
        </button>
      </div>
    </FormProvider>
  )
}
