import Table from "src/components/shared/antd-custom/table"
import Icon from "src/components/shared/components/material-icon"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {ColumnsType} from "antd/es/table"
import toDateTime from "src/utils/date-time"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {getOrderStatus} from "../../../details/help/getOrderStatus"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {toPrice} from "src/utils/price"
import React, {useContext, useEffect, useState} from "react"
import {Tooltip} from "antd"
import {ConfigContext} from "src/app"
import {GroupType} from "src/services/auth.service"
import transportationService from "src/services/transportation.service"
import {map} from "rxjs"

const RideListTableComponent = ({list = EMPTY_LIST}: {list: ListModel<TransferRideModel>}) => {
  const navigate = useNavigate()
  const {profile} = useContext(ConfigContext)
  const [agentMap, setAgentMap] = useState<Record<number, any>>({})
  const {t} = useTranslation()

  const columns: ColumnsType<any> = [
    {
      width: 44,
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (_, {id}) => <span className="font-semibold text-nowrap">{id}</span>
      // sorter: true
    },
    {
      width: 164,
      title: t("orders.main.status"),
      dataIndex: "status",
      key: "rent",
      render: (_, {status}) => {
        const {text, bg} = getOrderStatus(status)
        return (
          <div
            className="text-[13px] text-nowrap text-center  p-2 rounded-lg "
            style={{backgroundColor: bg + "33", color: bg, fontWeight: 600}}>
            {t(text as any)}
          </div>
        )
      }
      // sorter: true
    },
    {
      width: 148,
      title: t("orders.list.rent_start"),
      dataIndex: "start_at",
      key: "ride_start",
      render: (_, {start_at}) => <span className="font-semibold text-nowrap">{toDateTime(start_at)}</span>
      // sorter: true
    },
    {
      width: 148,
      title: t("transfers.table.route"),
      dataIndex: "from-to",
      key: "from-to",
      render: (_, {start_address, end_address, start_extra}) =>
        start_address && end_address ? (
          <>
            <div className="flex gap-2">
              <div className="flex items-center gap-1">
                {start_extra?.tour ? (
                  <Tooltip title={"Экскурсия"}>
                    <div className="rotate-90">
                      <Icon icon={"sync_alt"} />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="flex flex-col items-end gap-2">
                    <span className="font-bold">{t("transfers.form.from")}:</span>
                    <span className="font-bold">{t("transfers.form.to")}:</span>
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  <span>{start_address.name}</span>
                  <span>{end_address.name}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex gap-2 items-center">
            <div className="font-semibold text-sm">Нет Маршрута</div>
          </div>
        )
      // sorter: true
    },
    {
      width: 148,
      title: t("orders.list.client"),
      dataIndex: "client",
      key: "client",
      render: (_, {client_name, client_phone}) =>
        client_name ? (
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-sm">{client_name}</div>
            <div className="font-normal text-sm">{client_phone}</div>
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            <div className="font-semibold text-sm">{t("orders.list.no_client")}</div>
          </div>
        )
      // sorter: true
    },
    {
      width: 148,
      title: `${t("transfers.main.driver")}/${t("transfers.main.car")}`,
      dataIndex: "from-to",
      key: "from-to",
      render: (_, {driver_inventory_name, driver_inventory_unique_id, driver_user_name}) =>
        driver_inventory_name && (
          <div className="flex flex-col gap">
            <span>
              {driver_inventory_name} ({driver_inventory_unique_id})
            </span>
            <span>{driver_user_name}</span>
          </div>
        )
      // sorter: true
    },
    {
      width: 148,
      title: t("transfers.form.price"),
      dataIndex: "price",
      key: "price",
      render: (_, {price}) => (
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-sm">{toPrice(+price)}</div>
        </div>
      ),
      // sorter: true,
      hidden: profile && profile.group_type === GroupType.DRIVER
    },
    {
      width: 148,
      title: t("transfers.form.customer"),
      dataIndex: "agent",
      key: "agent",
      render: (_, {agent}) =>
        agent && (
          <div className="flex flex-col gap-1">
            <div className="font-semibold text-sm">{agentMap[agent]?.name}</div>
          </div>
        ),
      // sorter: true,
      hidden: profile && profile.group_type === GroupType.DRIVER
    }
  ]

  const onNavigate = (ride) => {
    if (!profile) return
    if (profile.group_type === GroupType.DRIVER) navigate(`/rides/${ride.id}`)
    else {
      navigate(`/transportation/${ride.transfer}/ride/${ride.id}`)
    }
  }

  useEffect(() => {
    const sub = transportationService.agents$
      .pipe(map((list) => list.reduce((p, c) => ({...p, [c.id]: c}), {})))
      .subscribe(setAgentMap)
    return () => sub.unsubscribe()
  }, [])

  return (
    <>
      <Table
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={list.results}
        onNavigate={(ride) => onNavigate(ride)}
      />
      <PaginationFormComponent count={list.count} />
    </>
  )
}

export default RideListTableComponent
